import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Box, Button, CircularProgress, Grid, Modal, ModalDialog, Stack, Typography } from '@mui/joy'
import React, { ReactNode } from 'react'

interface BaseModalProps {
  open: boolean
  title: string
  content: ReactNode
  icon?: 'cancel' | 'error' | 'pending'
  onClose?: () => void
  buttons?: ReactNode
  container?: Element
  maxWidth?: string
}

export const BaseModal = ({ open, title, content, onClose, icon, buttons, container, maxWidth }: BaseModalProps) => {
  const iconWidth = '86px'
  const iconStrokeWidth = '1'
  const icons = {
    cancel: (
      <XCircleIcon
        strokeWidth={iconStrokeWidth}
        style={{
          width: iconWidth,
          color: 'var(--joy-palette-neutral-plainDisabledColor)',
        }}
      />
    ),
    error: (
      <ExclamationCircleIcon
        strokeWidth={iconStrokeWidth}
        style={{
          width: iconWidth,
          color: 'var(--joy-palette-danger-plainColor)',
        }}
      />
    ),
    pending: <CircularProgress size="lg" />,
  }
  return (
    <Modal
      open={open}
      onClose={() => onClose?.()}
      container={container}
      sx={{ position: 'absolute' }}
      slotProps={{ backdrop: { sx: { position: 'inherit' } } }}
    >
      <ModalDialog sx={{ maxWidth: maxWidth ?? '360px', padding: '45px' }}>
        <Stack
          gap={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          {icon && icons[icon]}
          <Stack gap={1}>
            <Typography level="h2">{title}</Typography>
            <Box>
              {typeof content === 'string' ? (
                <Typography
                  color="neutral"
                  level="body-md-light"
                >
                  {content}
                </Typography>
              ) : (
                content
              )}
            </Box>
          </Stack>
          {buttons}
        </Stack>
      </ModalDialog>
    </Modal>
  )
}

interface JoyConfirmationModalProps extends Omit<BaseModalProps, 'buttons'> {
  onAccept: () => void
}

const JoyConfirmationModal = ({ onAccept, onClose, ...rest }: JoyConfirmationModalProps) => {
  const buttons = (
    <Grid
      container
      spacing={1}
      sx={{ width: '100%', marginTop: 1 }}
    >
      <Grid xs={6}>
        <Button
          sx={{ width: '100%' }}
          color="neutral"
          onClick={() => onClose?.()}
        >
          Cancelar
        </Button>
      </Grid>
      <Grid xs={6}>
        <Button
          sx={{ width: '100%' }}
          onClick={() => onAccept()}
        >
          Aceptar
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <BaseModal
      {...rest}
      onClose={onClose}
      buttons={buttons}
    />
  )
}

export default JoyConfirmationModal
