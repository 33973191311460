import React from 'react'
import { Module } from '@types'
import { UseNotifications } from '@hooks/useNotificationsSettings'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Switch, Typography } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import { Divider } from '@mui/material'
import { useSession } from '@hooks/useSession'

const styles = {
  notificationsBox: {
    border: globalStyles.border,
    borderRadius: '8px',
    py: 1,
    px: 2,
  },
}

const NotificationsSettings = () => {
  const { client } = useSession()
  const { handleChangeAndSave, notification, isLoadingNotificationSetting } = UseNotifications()

  const getNotificationMessage = (module: string) => {
    const moduleNotifications = notification?.find((item) => item?.module === module)

    if (moduleNotifications) {
      return moduleNotifications?.in_app && moduleNotifications?.email
        ? `Las notificaciones llegarán a la App y al Email`
        : moduleNotifications.in_app
        ? `Las notificaciones llegarán a la App`
        : moduleNotifications.email
        ? `Las notificaciones llegarán al email`
        : `No llegarán notificaciones`
    }
  }

  const notificationCard = (title: string, name: string) => (
    <Box sx={styles.notificationsBox}>
      <Accordion>
        <AccordionSummary>
          <Typography
            level="title-lg-bold"
            color="neutral"
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 1 }}>
          <Grid
            container
            sx={{ my: 0.5 }}
          >
            <Grid md={3}>
              <Box
                display="flex"
                alignItems="center"
              >
                <Switch
                  onChange={() => handleChangeAndSave(name, 'in_app')}
                  checked={notification?.find((item) => item?.module === name)?.in_app || false}
                  disabled={isLoadingNotificationSetting}
                  size="sm"
                />
                <Typography sx={{ ml: 1 }}>In-App</Typography>
              </Box>
            </Grid>
            <Grid md={3}>
              <Box
                display="flex"
                alignItems="center"
              >
                <Switch
                  onChange={() => handleChangeAndSave(name, 'email')}
                  checked={notification?.find((item) => item?.module === name)?.email || false}
                  disabled={isLoadingNotificationSetting}
                  size="sm"
                />
                <Typography sx={{ ml: 1 }}>Email</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ alignSelf: 'center', mt: 1.5 }}
          >
            <Typography
              level="body-sm-light"
              color="neutral"
            >
              {getNotificationMessage(name)}
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  )

  const hasMediaSiteModule = (client?.modules as Module[])?.some((module) => module.code === 'SITE')

  return (
    <>
      <Divider sx={{ mb: 3, mx: 3 }} />
      <Grid
        container
        spacing={2}
        sx={{ paddingX: 3 }}
      >
        {Boolean(client?.id) &&
          (client?.modules as Module[])?.map((module) => (
            <Grid
              md={6}
              key={module.code}
            >
              {notificationCard(module.title, module.code)}
            </Grid>
          ))}
        {!hasMediaSiteModule && <Grid md={6}>{notificationCard('MediaSite', 'SITE')}</Grid>}
      </Grid>
    </>
  )
}

export default NotificationsSettings
