import { authRoles } from '@constants'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import { Role } from '@types'
import { showConfirmationModal } from '@slices/modalSlice'

export const useSession = () => {
  const { client, user } = useAppSelector((state) => state.user)

  const userRole = (user?.role as Role)?.name

  const userLevel = (role: keyof typeof authRoles) => authRoles[role].includes(userRole)

  const dispatch = useAppDispatch()

  const showDrawerCloseModal = ({ onAccept }: { onAccept: () => void }) =>
    dispatch(
      showConfirmationModal({
        title: 'Cancelar edición',
        content: '¿Estás seguro que deseas cancelar la edición? Se perderán los cambios no guardados.',
        icon: 'cancel',
        onAccept,
      }),
    )

  const hasBlogNewsCreatePermission = client?.permission_summary?.BLOG?.['blog-news']?.POST
  const hasBlogNewsUpdatePermission = client?.permission_summary?.BLOG?.['blog-news']?.PATCH
  const hasBlogNewsDeletePermission = client?.permission_summary?.BLOG?.['blog-news']?.DELETE
  const hasBlogNewsReadPermission = client?.permission_summary?.BLOG?.['blog-news']?.GET

  const hasBlogNewsPublishPermission = client?.permission_summary?.BLOG?.['blog-news']?.PUBLISH
  const hasBlogTagsCreatePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.POST
  const hasBlogTagsUpdatePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.PATCH
  const hasBlogTagsDeletePermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.DELETE
  const hasBlogTagsReadPermission = client?.permission_summary?.BLOG?.['blog-news-tags']?.GET

  const hasInvestorNewsCreatePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.POST
  const hasInvestorNewsUpdatePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.PATCH
  const hasInvestorNewsDeletePermission = client?.permission_summary?.INVESTOR?.['investors-news']?.DELETE
  const hasInvestorNewsReadPermission = client?.permission_summary?.INVESTOR?.['investors-news']?.GET
  const hasInvestorNewsPublishPermission = client?.permission_summary?.INVESTOR?.['investors-news']?.PUBLISH

  const hasCustomLoginReadPermission = client?.permission_summary?.CORE?.['customlogin']?.GET

  const isInvestor = client?.permission_summary?.INVESTOR?.['is-investor']?.GET
  const isOwner = client?.permission_summary?.OWNER?.['is-owner']?.GET

  const hasInvestorsCreatePermission = client?.permission_summary?.INVESTOR?.['investors']?.POST
  const hasInvestorsUpdatePermission = client?.permission_summary?.INVESTOR?.['investors']?.PATCH
  const hasInvestorsDeletePermission = client?.permission_summary?.INVESTOR?.['investors']?.DELETE
  const hasInvestorsReadPermission = client?.permission_summary?.INVESTOR?.['investors']?.GET

  const hasOwnersCreatePermission = client?.permission_summary?.OWNER?.['owners']?.POST
  const hasOwnersUpdatePermission = client?.permission_summary?.OWNER?.['owners']?.PATCH
  const hasOwnersDeletePermission = client?.permission_summary?.OWNER?.['owners']?.DELETE
  const hasOwnersReadPermission = client?.permission_summary?.OWNER?.['owners']?.GET

  const hasOwnerNewsCreatePermission = client?.permission_summary?.OWNER?.['owners-news']?.POST
  const hasOwnerNewsUpdatePermission = client?.permission_summary?.OWNER?.['owners-news']?.PATCH
  const hasOwnerNewsDeletePermission = client?.permission_summary?.OWNER?.['owners-news']?.DELETE
  const hasOwnerNewsReadPermission = client?.permission_summary?.OWNER?.['owners-news']?.GET
  const hasOwnerNewsPublishPermission = client?.permission_summary?.OWNER?.['owners-news']?.PUBLISH

  const hasOwnerAppraisalsCreatePermission = client?.permission_summary?.OWNER?.['owners-appraisal']?.POST
  const hasOwnerAppraisalsUpdatePermission = client?.permission_summary?.OWNER?.['owners-appraisal']?.PATCH
  const hasOwnerAppraisalsDeletePermission = client?.permission_summary?.OWNER?.['owners-appraisal']?.DELETE
  const hasOwnerAppraisalsReadPermission = client?.permission_summary?.OWNER?.['owners-appraisal']?.GET
  const hasOwnerAppraisalsPublishPermission = client?.permission_summary?.OWNER?.['owners-appraisal']?.PUBLISH

  const hasOwnerReportsCreatePermission = client?.permission_summary?.OWNER?.['owners-reports']?.POST
  const hasOwnerReportsUpdatePermission = client?.permission_summary?.OWNER?.['owners-reports']?.PATCH
  const hasOwnerReportsDeletePermission = client?.permission_summary?.OWNER?.['owners-reports']?.DELETE
  const hasOwnerReportsReadPermission = client?.permission_summary?.OWNER?.['owners-reports']?.GET
  const hasOwnerReportsPublishPermission = client?.permission_summary?.OWNER?.['owners-reports']?.PUBLISH

  const hasOwnerVisitsCreatePermission = client?.permission_summary?.OWNER?.['owners-visits']?.POST
  const hasOwnerVisitsReadPermission = client?.permission_summary?.OWNER?.['owners-visits']?.GET
  const hasOwnerVisitsUpdatePermission = client?.permission_summary?.OWNER?.['owners-visits']?.PATCH
  const hasOwnerVisitsDeletePermission = client?.permission_summary?.OWNER?.['owners-visits']?.DELETE

  const hasJobsApplicationsCreatePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.POST
  const hasJobsApplicationsUpdatePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.PATCH
  const hasJobsApplicationsDeletePermission = client?.permission_summary?.JOBS?.['jobs-applications']?.DELETE
  const hasJobsApplicationsReadPermission = client?.permission_summary?.JOBS?.['jobs-applications']?.GET

  const hasJobsPositionsCreatePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.POST
  const hasJobsPositionsUpdatePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.PATCH
  const hasJobsPositionsDeletePermission = client?.permission_summary?.JOBS?.['jobs-positions']?.DELETE
  const hasJobsPositionsReadPermission = client?.permission_summary?.JOBS?.['jobs-positions']?.GET
  const hasJobsPositionsPublishPermission = client?.permission_summary?.JOBS?.['jobs-positions']?.PUBLISH

  const hasTicketsCreatePermission = client?.permission_summary?.CORE?.['support-tickets']?.POST

  const hasUsersPermission = client?.permission_summary?.CORE?.['users']?.ADMIN

  const hasPushActionsCreatePermission = client?.permission_summary?.PUSH?.['push-actions']?.POST
  const hasPushActionsUpdatePermission = client?.permission_summary?.PUSH?.['push-actions']?.PATCH
  const hasPushActionsDeletePermission = client?.permission_summary?.PUSH?.['push-actions']?.DELETE
  const hasPushActionsReadPermission = client?.permission_summary?.PUSH?.['push-actions']?.GET
  const hasPushActionsPublishPermission = client?.permission_summary?.PUSH?.['push-actions']?.PUBLISH

  const hasTrackerAnalyticsReadPermission = client?.permission_summary?.TRACKER?.['analytics']?.GET
  const hasTrackerAnalyticsCreatePermission = client?.permission_summary?.TRACKER?.['analytics-property']?.POST
  const hasTrackerReportsReadPermission = client?.permission_summary?.TRACKER?.['analytics-reportes']?.GET
  const hasTrackerRealtimeReportsReadPermission =
    client?.permission_summary?.TRACKER?.['analytics-reportes-realtime']?.GET

  const hasSitePropertiesReadPermission = client?.permission_summary?.SITE?.['sites-properties']?.GET
  const hasSitePropertiesUpdatePermission = client?.permission_summary?.SITE?.['sites-properties']?.PATCH
  const hasSitePropertiesCreatePermission = client?.permission_summary?.SITE?.['sites-properties']?.POST
  const hasSitePropertiesDeletePermission = client?.permission_summary?.SITE?.['sites-properties']?.DELETE
  const hasSitePropertiesPublishPermission = client?.permission_summary?.SITE?.['sites-properties']?.PUBLISH

  const hasSiteDevelopmentsReadPermission = client?.permission_summary?.SITE?.['sites-developments']?.GET
  const hasSiteDevelopmentsUpdatePermission = client?.permission_summary?.SITE?.['sites-developments']?.PATCH
  const hasSiteDevelopmentsCreatePermission = client?.permission_summary?.SITE?.['sites-developments']?.POST
  const hasSiteDevelopmentsDeletePermission = client?.permission_summary?.SITE?.['sites-developments']?.DELETE
  const hasSiteDevelopmentsPublishPermission = client?.permission_summary?.SITE?.['sites-developments']?.PUBLISH

  const hasAgencyResourcesCreatePermission = client?.permission_summary?.AGENCY?.['agency-resources']?.POST
  const hasAgencyResourcesUpdatePermission = client?.permission_summary?.AGENCY?.['agency-resources']?.PATCH
  const hasAgencyResourcesDeletePermission = client?.permission_summary?.AGENCY?.['agency-resources']?.DELETE
  const hasAgencyResourcesReadPermission = client?.permission_summary?.AGENCY?.['agency-resources']?.GET

  const hasAgencyResourceEventsCreatePermission = client?.permission_summary?.AGENCY?.['agency-resource-events']?.POST
  const hasAgencyResourceEventsUpdatePermission = client?.permission_summary?.AGENCY?.['agency-resource-events']?.PATCH
  const hasAgencyResourceEventsDeletePermission = client?.permission_summary?.AGENCY?.['agency-resource-events']?.DELETE
  const hasAgencyResourceEventsReadPermission = client?.permission_summary?.AGENCY?.['agency-resource-events']?.GET

  const hasAgencyRestrictedResourcesCreatePermission =
    client?.permission_summary?.AGENCY?.['agency-resources-restricted']?.POST
  const hasAgencyRestrictedResourcesUpdatePermission =
    client?.permission_summary?.AGENCY?.['agency-resources-restricted']?.PATCH

  const hasSitesReadPermission = client?.permission_summary?.SITE?.['sites']?.GET

  const hasUsersAdminPermission = client?.permission_summary?.CORE?.['users']?.ADMIN

  const isProspect = client?.status === 'Prospecto'

  return {
    client,
    user,
    hasBlogNewsCreatePermission,
    hasBlogNewsUpdatePermission,
    hasBlogNewsDeletePermission,
    hasBlogNewsReadPermission,
    hasBlogNewsPublishPermission,
    hasBlogTagsCreatePermission,
    hasBlogTagsUpdatePermission,
    hasBlogTagsDeletePermission,
    hasBlogTagsReadPermission,
    hasInvestorNewsCreatePermission,
    hasInvestorNewsUpdatePermission,
    hasInvestorNewsDeletePermission,
    hasInvestorNewsReadPermission,
    hasInvestorNewsPublishPermission,
    hasCustomLoginReadPermission,
    isInvestor,
    isOwner,
    hasInvestorsCreatePermission,
    hasInvestorsUpdatePermission,
    hasInvestorsDeletePermission,
    hasInvestorsReadPermission,
    hasOwnerNewsCreatePermission,
    hasOwnerNewsUpdatePermission,
    hasOwnerNewsDeletePermission,
    hasOwnerNewsReadPermission,
    hasOwnerAppraisalsCreatePermission,
    hasOwnerAppraisalsUpdatePermission,
    hasOwnerAppraisalsDeletePermission,
    hasOwnerAppraisalsReadPermission,
    hasOwnerReportsCreatePermission,
    hasOwnerReportsUpdatePermission,
    hasOwnerReportsDeletePermission,
    hasOwnerReportsReadPermission,
    hasOwnerNewsPublishPermission,
    hasOwnerAppraisalsPublishPermission,
    hasOwnerReportsPublishPermission,
    hasOwnerVisitsCreatePermission,
    hasOwnerVisitsReadPermission,
    hasOwnerVisitsUpdatePermission,
    hasOwnerVisitsDeletePermission,
    hasOwnersCreatePermission,
    hasOwnersUpdatePermission,
    hasOwnersDeletePermission,
    hasOwnersReadPermission,
    hasJobsApplicationsCreatePermission,
    hasJobsApplicationsUpdatePermission,
    hasJobsApplicationsDeletePermission,
    hasJobsApplicationsReadPermission,
    hasJobsPositionsCreatePermission,
    hasJobsPositionsUpdatePermission,
    hasJobsPositionsDeletePermission,
    hasJobsPositionsReadPermission,
    hasJobsPositionsPublishPermission,
    hasTicketsCreatePermission,
    hasUsersPermission,
    hasPushActionsCreatePermission,
    hasPushActionsUpdatePermission,
    hasPushActionsDeletePermission,
    hasPushActionsReadPermission,
    hasPushActionsPublishPermission,
    hasTrackerAnalyticsReadPermission,
    hasTrackerAnalyticsCreatePermission,
    hasTrackerReportsReadPermission,
    hasTrackerRealtimeReportsReadPermission,
    hasSitePropertiesReadPermission,
    hasSitePropertiesUpdatePermission,
    hasSitePropertiesCreatePermission,
    hasSitePropertiesPublishPermission,
    hasSitePropertiesDeletePermission,
    hasSiteDevelopmentsReadPermission,
    hasSiteDevelopmentsUpdatePermission,
    hasSiteDevelopmentsCreatePermission,
    hasSiteDevelopmentsDeletePermission,
    hasSiteDevelopmentsPublishPermission,
    hasUsersAdminPermission,
    hasSitesReadPermission,
    isProspect,
    userLevel,
    showDrawerCloseModal,
    hasAgencyResourcesCreatePermission,
    hasAgencyResourcesDeletePermission,
    hasAgencyResourcesReadPermission,
    hasAgencyResourcesUpdatePermission,
    hasAgencyRestrictedResourcesCreatePermission,
    hasAgencyRestrictedResourcesUpdatePermission,
    hasAgencyResourceEventsCreatePermission,
    hasAgencyResourceEventsDeletePermission,
    hasAgencyResourceEventsReadPermission,
    hasAgencyResourceEventsUpdatePermission,
  }
}
