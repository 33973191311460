import React from 'react'
import AlertContextWrapper from '@components/AlertContextWrapper'
import Auth from '@components/Auth'
import NavBarsWrapper from '@components/NavBarsWrapper'
import { store } from '@redux/store'
import { Provider } from 'react-redux'
import ThemeWrapper from '@components/ThemeWrapper'
import MapsWrapper from '@components/MapsWrapper'

declare module '@mui/joy/styles' {
  interface TypographySystemOverrides {
    'title-lg-light': true
    'title-lg-bold': true
    'body-sm-light': true
    'body-sm-bold': true
    'body-md-light': true
    'body-md-bold': true
  }
}

export const wrapRootElement = ({ element }: any) => {
  return (
    <Provider store={store}>
      <Auth>
        <ThemeWrapper>
          <MapsWrapper>{element}</MapsWrapper>
        </ThemeWrapper>
      </Auth>
    </Provider>
  )
}

export const wrapPageElement = ({ element, props }: any) => {
  if (!props.location.pathname.includes('/sesion/inicio'))
    return (
      <AlertContextWrapper>
        {props.location.pathname.includes('/sesion/inicio') ? (
          element
        ) : (
          <NavBarsWrapper {...props}>{element}</NavBarsWrapper>
        )}
      </AlertContextWrapper>
    )
}
