import { Box, Input, Typography } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import moment from 'moment'
import React, { ReactNode } from 'react'

interface FloatingLabelDateInputProps {
  label?: ReactNode
  required?: boolean
}

export const FloatingLabelDateInput = ({
  label,
  required,
  ...rest
}: FloatingLabelDateInputProps & React.ComponentProps<typeof Input>) => {
  const formattedValue =
    typeof rest.value === 'string'
      ? rest.type === 'date'
        ? moment(rest.value).format('YYYY-MM-DD')
        : moment(rest.value).format('YYYY-MM-DDTHH:mm')
      : rest.value
  return (
    <Box position="relative">
      {label && (
        <Typography
          color="neutral"
          sx={{
            ...(required ? globalStyles.requiredFieldLabel : {}),
            position: 'absolute',
            top: '-0.5rem',
            fontSize: '0.75rem',
            backgroundColor: 'var(--joy-palette-background-surface)',
            zIndex: 2,
            padding: '0 0.25rem',
            marginLeft: '0.75rem',
          }}
        >
          {label ?? ''}
        </Typography>
      )}
      <Input
        type="datetime-local"
        {...rest}
        value={formattedValue}
        onChange={(e) => {
          const { ...eventRest } = e
          const isoValue = moment(eventRest.target.value).toISOString() ?? ''
          rest.onChange?.({
            ...eventRest,
            target: {
              ...eventRest.target,
              value: isoValue,
            },
          })
        }}
        sx={{
          ...rest.sx,
        }}
      ></Input>
    </Box>
  )
}
