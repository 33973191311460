import React, { ChangeEvent, useState } from 'react'
import SpinnerWrapper from '@components/SpinnerWrapper'
import { auth, translateFirebaseError } from '@firebaseUtils/firebase'
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { PasswordInput } from '@components/PasswordInput'
import { Button, FormLabel, Grid, Typography } from '@mui/joy'
import { toast } from 'react-toastify'
import { FloatingLabel } from './FloatingLabel'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [oldPassWordError, setOldPassWordError] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false)
  const [score, setScore] = useState(0)

  const isBusy = isUpdatingPassword

  return (
    <SpinnerWrapper>
      {auth.currentUser?.providerData.length === 1 && auth.currentUser.providerData[0].providerId === 'password' ? (
        <>
          <Typography
            level="h3"
            sx={{ mb: 1, mt: 2 }}
          >
            Cambiar contraseña
          </Typography>
          <Grid
            container
            sx={{ mb: 1, mt: 1 }}
          >
            <Grid
              md={12}
              xs={12}
            >
              <FloatingLabel label="Contraseña anterior">
                <PasswordInput
                  value={oldPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.persist()
                    setOldPassWordError('')
                    setOldPassword(e.target.value)
                  }}
                />
              </FloatingLabel>
              <FormLabel className="text-danger">{oldPassWordError}</FormLabel>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Grid
              md={6}
              xs={12}
            >
              <FloatingLabel label="Nueva contraseña">
                <PasswordInput
                  onChangeScore={setScore}
                  showStrengthBar
                  value={password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.persist()
                    setPassword(e.target.value)
                  }}
                />
              </FloatingLabel>
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <FloatingLabel label="Repetir contraseña">
                <PasswordInput
                  value={repeatPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.persist()
                    setRepeatPassword(e.target.value)
                  }}
                />
              </FloatingLabel>
              {password !== repeatPassword && (
                <FormLabel className="text-danger">Las contraseñas no coinciden</FormLabel>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ justifyContent: 'flex-end', mt: 1 }}
          >
            <Grid>
              <Button
                disabled={
                  password !== repeatPassword || !auth.currentUser || isUpdatingPassword || !score || !oldPassword
                }
                onClick={() => {
                  setIsUpdatingPassword(true)
                  const credential = EmailAuthProvider.credential(auth.currentUser!.email!, oldPassword)
                  reauthenticateWithCredential(auth.currentUser!, credential)
                    .then(async () => {
                      await toast
                        .promise(updatePassword(auth.currentUser!, password), {
                          pending: `Cambiando contraseña...`,
                          success: `Contraseña cambiada con éxito`,
                          error: `No se pudo cambiar la contraseña`,
                        })
                        .then(() => {
                          setOldPassword('')
                          setPassword('')
                          setRepeatPassword('')
                        })
                        .catch((e: any) => {
                          console.log(e)
                        })
                        .finally(() => setIsUpdatingPassword(false))
                    })
                    .catch((error: any) => setOldPassWordError(translateFirebaseError(error.code)))
                    .finally(() => setIsUpdatingPassword(false))
                }}
                loading={isBusy}
              >
                Guardar contraseña
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </SpinnerWrapper>
  )
}

export default ChangePassword
