import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import { CircularProgress, Drawer, IconButton, Typography } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import React, { ReactNode, useEffect, useRef } from 'react'
import { BaseModal } from './JoyConfirmationModal'
import { navigate } from 'gatsby'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { DrawerTitle } from './tables/UserCommunicationsTable'

interface LayoutProps {
  leftHeader?: React.ReactNode
  leftBody?: React.ReactNode
  rightHeader?: React.ReactNode
  rightBody?: React.ReactNode
  loaders?: {
    left?: boolean
    right?: boolean
  }
  hiddenLeftPanel?: boolean
  hiddenTopBar?: boolean
  customBodyHeight?: string
  error?: boolean
  onErrorReload?: () => void
  forbidden?: boolean
  blankLeftPanel?: boolean // Temporary workaround
}

const appVersionHeight = 35

const Layout = ({
  leftHeader,
  leftBody,
  rightHeader,
  rightBody,
  loaders,
  hiddenLeftPanel,
  hiddenTopBar,
  customBodyHeight,
  error,
  onErrorReload,
  forbidden,
  blankLeftPanel,
}: LayoutProps) => {
  const [open, setOpen] = React.useState(false)

  const handleToggleDrawer = () => {
    setOpen(!open)
  }

  const topBarHeight = hiddenTopBar ? 0 : globalStyles.topBarHeight

  const totalNavBarHeight = globalStyles.navBarHeight + topBarHeight

  const bottomNavBarHeight = globalStyles.navBarHeight

  const rightBodyHeight = {
    xs: customBodyHeight ?? `calc(100vh - ${totalNavBarHeight + bottomNavBarHeight}px)`,
    sm: customBodyHeight ?? `calc(100vh - ${totalNavBarHeight}px)`,
  }

  const styles = {
    body: {
      maxHeight: `calc(100vh - ${globalStyles.navBarHeight}px)`,
      position: 'relative', // Error modal
    },
    leftPanel: {
      globalMobile: {
        content: {
          sx: {
            width: { xs: '100%', md: globalStyles.leftPanelWidth },
          },
        },
      },
      global: {
        display: { xs: 'none', md: 'block' },
        minHeight: {
          xs: customBodyHeight ?? `calc(100vh - ${totalNavBarHeight}px)`,
          sm: customBodyHeight ?? `calc(100vh - ${totalNavBarHeight}px)`,
        },
        minWidth: globalStyles.leftPanelWidth,
        maxWidth: globalStyles.leftPanelWidth,
        borderRight: globalStyles.border,
        position: 'relative',
      },
      header: {
        height: topBarHeight,
        ...(!blankLeftPanel && { borderBottom: globalStyles.border }),
      },
      body: {
        position: 'relative',
        paddingX: 4,
        paddingY: 3,
        maxHeight: {
          xs: `calc(100vh - ${totalNavBarHeight + appVersionHeight}px)`,
          sm: `calc(100vh - ${totalNavBarHeight + appVersionHeight}px)`,
        },
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    rightPanel: {
      header: {
        paddingX: 4,
        height: topBarHeight,
        borderBottom: globalStyles.border,
      },
      body: {
        position: 'relative',
        maxHeight: rightBodyHeight,
        minHeight: rightBodyHeight,
        overflowY: 'auto',
      },
    },
    bottomBar: {
      display: { xs: 'block', md: 'none' },
      width: '100vw',
      height: globalStyles.navBarHeight,
      borderTop: globalStyles.border,
      position: 'absolute',
      bottom: 0,
      zIndex: 1021,
      backgroundColor: 'var(--joy-palette-background-surface)',
    },
  }

  const leftPanelContent = (
    <>
      {!hiddenTopBar && <Stack sx={styles.leftPanel.header}>{leftHeader}</Stack>}
      <Box sx={styles.leftPanel.body}>
        {leftBody}
        <Loader show={loaders?.left} />
      </Box>
      <AppVersionBox />
    </>
  )

  const layoutRef = useRef<HTMLDivElement>(null)

  const [isMounted, setIsMounted] = React.useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  return !isMounted ? (
    <></>
  ) : (
    <>
      <Stack
        ref={layoutRef}
        sx={styles.body}
        direction="row"
      >
        {!hiddenLeftPanel && <Stack sx={styles.leftPanel.global}>{leftPanelContent}</Stack>}
        <Stack
          id="bottom-body"
          sx={{ width: '100%' }}
        >
          {!hiddenTopBar && (
            <Stack
              direction="row"
              sx={styles.rightPanel.header}
            >
              {rightHeader}
            </Stack>
          )}
          <Box sx={styles.rightPanel.body}>
            {rightBody}
            <Loader show={loaders?.right} />
          </Box>
        </Stack>
      </Stack>
      {true && (
        <Stack
          sx={styles.bottomBar}
          direction={'row'}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              height: globalStyles.navBarHeight,
              width: 92.35,
              borderRight: globalStyles.border,
            }}
          >
            <IconButton
              variant="plain"
              onClick={handleToggleDrawer}
            >
              <AdjustmentsHorizontalIcon width="1.5em" />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {!hiddenLeftPanel && (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          slotProps={styles.leftPanel.globalMobile}
        >
          <DrawerTitle title="Filtros" />
          {open && <Stack sx={styles.leftPanel.globalMobile}>{leftPanelContent}</Stack>}
        </Drawer>
      )}
      <BaseModal
        container={layoutRef.current ?? undefined}
        icon="error"
        open={Boolean(error)}
        title="Error de carga"
        content="El módulo no cargó de manera correcta. Por favor cargar la página de vuelta"
        buttons={
          <Button
            sx={{ width: '100%' }}
            onClick={onErrorReload}
          >
            Volver a cargar
          </Button>
        }
      />
      <BaseModal
        container={layoutRef.current ?? undefined}
        maxWidth="470px"
        open={Boolean(forbidden)}
        title="Acceso no disponible"
        content={
          <Stack gap={1}>
            <Typography level="title-lg-bold">Este módulo no está activado en su espacio de trabajo</Typography>
            <Typography
              level="body-md-light"
              color="neutral"
            >
              Contacta a tu agente comercial de MediaCore para solicitar la activación de este módulo.
            </Typography>
          </Stack>
        }
        buttons={
          <Button
            sx={{ width: '100%' }}
            onClick={() => navigate('/')}
          >
            Volver a inicio
          </Button>
        }
      />
    </>
  )
}

interface LoaderProps {
  show?: boolean
}

export const Loader = ({ show }: LoaderProps) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'var(--mui-palette-text-primary)',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: show ? 0.3 : 0,
          zIndex: show ? 2 : -1,
          transition: 'opacity 0.3s',
          cursor: 'wait',
        }}
      ></Box>
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: 'opacity 0.3s',
          opacity: show ? 1 : 0,
          zIndex: show ? undefined : -1,
          cursor: 'wait',
        }}
      />
    </>
  )
}

export default Layout

export const FiltersBox = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        paddingX: 2,
        paddingY: 1,
        marginBottom: 2,
        border: globalStyles.border,
        borderRadius: globalStyles.borderRadius,
      }}
    >
      {children}
    </Box>
  )
}

const AppVersionBox = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        borderTop: globalStyles.border,
        height: appVersionHeight,
      }}
    >
      <Typography
        color="neutral"
        level="body-md-bold"
      >
        MediaCore® v3.0
      </Typography>
    </Box>
  )
}
