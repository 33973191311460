import React, { useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AspectRatio,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Typography,
  styled,
} from '@mui/joy'
import { JoyStyles } from '@types'
import { globalStyles } from '@styles/styles'
import { Check, ErrorOutline, KeyboardArrowRight } from '@mui/icons-material'
import { PieChart } from '@mui/x-charts/PieChart'
import { useDrawingArea } from '@mui/x-charts/hooks'
import { useColorScheme, useTheme } from '@mui/joy/styles'
import { Rating } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useAppSelector } from '@redux/reduxHooks'
import { PreviewHeaderSkeleton } from './Skeletons'
import { splitLines } from '@utils'
import { LinkIcon } from '@heroicons/react/16/solid'
import { computableTotalSurface } from '@constants'

interface PreviewSectionProps {
  title: string
  children: React.ReactNode
}

export const PreviewSection = ({ title, children }: PreviewSectionProps) => {
  const borderColor = 'var(--joy-palette-primary-plainColor)'
  const styles: JoyStyles = {
    divider: {
      marginBottom: 3,
      '--Divider-childPosition': `3%`,
      color: borderColor,
      '&::before, &::after': {
        background: borderColor,
      },
    },
  }
  return (
    <Stack sx={{ paddingY: 3 }}>
      <Divider sx={styles.divider}>
        <Typography
          color="primary"
          level="h4"
        >
          {title}
        </Typography>
      </Divider>
      {children}
    </Stack>
  )
}

interface PreviewHeaderProps {
  title?: string
  headline?: string
  date?: string
  date_from?: string
  date_to?: string
  author?: string
  loading?: boolean
}

export const PreviewHeader = ({
  title,
  headline,
  date = '',
  date_from,
  date_to,
  author,
  loading,
}: PreviewHeaderProps) =>
  loading ? (
    <PreviewHeaderSkeleton />
  ) : (
    <Stack
      sx={{ padding: 3 }}
      width={'100%'}
    >
      <Typography level="body-md-light">{date}</Typography>
      {date_from && date_to && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography level="body-md-bold">Período</Typography>
          <Typography level="body-md-light">{`${date_from} al ${date_to}`}</Typography>
        </Stack>
      )}
      <Typography
        level="h2"
        sx={{ my: 2 }}
      >
        {title}
      </Typography>
      <Typography level="h4">{headline}</Typography>
      {author && <Typography level="body-md-light">Publicado por {author}</Typography>}
    </Stack>
  )

interface ContentCarouselItem {
  title: string
  content: React.ReactNode
  hidden?: boolean
}

interface ContentCarouselProps {
  items: ContentCarouselItem[]
}

export const ContentCarousel = ({ items }: ContentCarouselProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const contentRef = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = React.useState(undefined as React.CSSProperties['height'])

  // **** This is the code that should be used to handle the scroll snap effect ****
  // const onScroll = () => {
  //   const contentChildren = Array.from(contentRef.current!.children)
  //   contentChildren?.forEach((child, index) => {
  //     if (!(child.getBoundingClientRect().top - contentRef.current!.getBoundingClientRect()?.top)) setActiveIndex(index)
  //   })
  // }

  // useEffect(() => {
  //   contentRef?.current?.addEventListener('scroll', () => onScroll())
  //   return () => {
  //     contentRef?.current?.removeEventListener('scroll', () => onScroll())
  //   }
  // }, [])
  // ******************************************************************************

  useEffect(() => {
    const contentChildren = Array.from(contentRef.current!.children).concat(leftPanelRef.current!)
    const contentChildrenMaxHeight = Math.max(...contentChildren.map((child) => child.clientHeight))
    if (contentChildrenMaxHeight !== height) {
      setHeight(`${contentChildrenMaxHeight}px`)
    }
  }, [height])

  const leftPanelRef = React.useRef<HTMLDivElement>(null)

  const styles: JoyStyles = {
    contentPanel: {
      scrollSnapType: 'y mandatory',
      overflow: 'hidden',
      height,
    },
    contentPanelItem: {
      scrollSnapAlign: 'start',
      paddingX: 3,
      minHeight: height,
      width: '100%',
    },
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ height: { sm: Number(height) + 50, md: height } }}
    >
      <Grid
        xs={12}
        md={3}
      >
        <Stack
          direction={{ xs: 'row', sm: 'row', md: 'column' }}
          gap={2}
          ref={leftPanelRef}
        >
          {items
            .filter(({ hidden }) => !hidden)
            .map(({ title }, index) => (
              <Button
                key={title}
                variant="plain"
                color="neutral"
                onClick={() => {
                  setActiveIndex(index)
                  const content = contentRef.current?.getElementsByClassName(`content-${index}`)?.[0]
                  content?.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                <Typography
                  level="h4"
                  sx={index === activeIndex ? {} : { color: globalStyles.secondaryTextColor }}
                >
                  {title}
                </Typography>
              </Button>
            ))}
        </Stack>
      </Grid>
      <Grid
        ref={contentRef}
        xs={12}
        md={9}
        sx={styles.contentPanel}
      >
        {items
          .filter(({ hidden }) => !hidden)
          .map(({ content }, index) => (
            <Stack
              className={`content-${index}`}
              key={index}
              sx={styles.contentPanelItem}
            >
              {content}
            </Stack>
          ))}
      </Grid>
    </Grid>
  )
}

interface InformationItemProps {
  header: string
  value: any
  alwaysShow: boolean
  valueToShow?: string
}

export const InformationItem = ({ header, value, alwaysShow, valueToShow }: InformationItemProps) =>
  Boolean(value) || alwaysShow ? (
    <Grid
      xs={4}
      sm={3}
    >
      <Stack direction="row">
        <KeyboardArrowRight />
        <Stack>
          <Typography level="body-sm-bold">{header}</Typography>
          <Typography level="body-sm-light">{valueToShow ?? value}</Typography>
        </Stack>
      </Stack>
    </Grid>
  ) : (
    <></>
  )

interface AmenitiesItemProps {
  value: string
}

export const AmenitiesItem = ({ value }: AmenitiesItemProps) => (
  <Grid
    xs={12}
    sm={3}
  >
    <Typography
      startDecorator={
        <AspectRatio
          variant="solid"
          color="primary"
          sx={{
            width: 10,
            borderRadius: '50%',
          }}
          ratio={1}
        >
          <Check />
        </AspectRatio>
      }
      level="body-sm-light"
    >
      {value}
    </Typography>
  </Grid>
)

interface PropertyStripeProps {
  image?: string
  title?: string
  subtitle?: string
  city?: string
  minWidth?: React.CSSProperties['minWidth']
  state?: string
  country?: string
  age?: string
  roofed_surface?: number
  semiroofed_surface?: number
  unroofed_surface?: number
  room_amount?: number
  suite_amount?: number
  bathroom_amount?: number
  parking_lot_amount?: number
  extra_amenities?: string
  valuation_price?: number
  suggested_price?: number
}

export const PropertyStripe = ({
  image,
  title,
  subtitle,
  city,
  minWidth,
  state,
  country,
  age,
  roofed_surface,
  semiroofed_surface,
  unroofed_surface,
  room_amount,
  suite_amount,
  bathroom_amount,
  parking_lot_amount,
  extra_amenities,
  valuation_price,
  suggested_price,
}: PropertyStripeProps) => {
  const styles: JoyStyles = {
    propertyStripe: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 2,
      border: globalStyles.border,
      borderRadius: '5px',
      height: 'min-content',
      minWidth,
      scrollSnapAlign: 'start',
    },
  }
  const computable_total_surface = computableTotalSurface({
    roofed_surface,
    semiroofed_surface,
    unroofed_surface,
  })
  const price = suggested_price ?? valuation_price
  const square_meter_value = Math.round(price && computable_total_surface ? price / computable_total_surface : 0)

  return (
    <Box sx={styles.propertyStripe}>
      <AspectRatio sx={{ width: '100%' }}>
        <img
          src={image}
          alt=""
        />
      </AspectRatio>
      <Accordion>
        <AccordionSummary>
          <Stack>
            <Typography level="body-md-bold">{title}</Typography>
            <Typography level="body-sm-light">{subtitle}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            gap={2}
            sx={{
              marginTop: 2,
            }}
          >
            <PropertyStripeRow
              label="Partido/Localidad"
              value={city}
            />
            <PropertyStripeRow
              label="Provincia"
              value={state}
            />
            <PropertyStripeRow
              label="País"
              value={country}
            />
            <PropertyStripeRow
              label="Antigüedad"
              value={age}
            />
            <PropertyStripeRow
              label="Sup. Cubierta"
              value={roofed_surface + 'm2'}
            />
            <PropertyStripeRow
              label="Sup. Semicubierta"
              value={semiroofed_surface + 'm2'}
            />
            <PropertyStripeRow
              label="Sup. Descubierta"
              value={unroofed_surface + 'm2'}
            />
            <PropertyStripeRow
              label="Superficie total computable"
              value={computable_total_surface + 'm2'}
            />
            <PropertyStripeRow
              label="Ambientes"
              value={room_amount}
            />
            <PropertyStripeRow
              label="Dormitorios"
              value={suite_amount}
            />
            <PropertyStripeRow
              label="Baños"
              value={bathroom_amount}
            />
            <PropertyStripeRow
              label="Cocheras"
              value={parking_lot_amount}
            />
            <PropertyStripeRow
              label="Amenities"
              value={extra_amenities}
            />
            <PropertyStripeRow
              label="Valor prom. por m2"
              value={square_meter_value}
            />
            {suggested_price !== undefined && (
              <PropertyStripeRow
                label="Valor sugerido MediaCore®"
                value={suggested_price}
              />
            )}
            {suggested_price === undefined && (
              <PropertyStripeRow
                label="Valor de publicación"
                value={valuation_price}
              />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const PropertyStripeRow = ({ label, value }: { label: string; value?: string | number }) => (
  <Stack>
    <Typography
      sx={{ color: globalStyles.secondaryTextColor }}
      level="body-sm-light"
    >
      {label}
    </Typography>
    <Typography level="body-md-light">{value ?? ''}</Typography>
  </Stack>
)

interface ValuationCardProps {
  title: string
  valuation_currency: string
  valuation_price: number
  valuation_price_has_percentage?: boolean
  valuation_price_percentage?: number
  valuation_period?: string
}

export const ValuationCard = ({
  valuation_currency,
  valuation_price,
  valuation_price_has_percentage,
  valuation_price_percentage,
  valuation_period,
  title,
}: ValuationCardProps) => (
  <Stack gap={1}>
    <Typography level="body-md-bold">{title}</Typography>
    <Stack
      direction="row"
      gap={2}
      alignItems={'center'}
    >
      <Typography level="h3">{`${valuation_currency} ${Number(valuation_price).toLocaleString('es-AR')}`}</Typography>
      {valuation_price_has_percentage && (
        <Typography level="body-md-light">{`+/- ${valuation_price_percentage}%`}</Typography>
      )}
    </Stack>
    {Boolean(valuation_period) && <Typography level="body-md-light">{valuation_period}</Typography>}
  </Stack>
)

interface PieCardProps {
  data: any
  total: number | string
  headers: (string | number)[]
  totalLabel: string
  title?: string
  days?: number
  showPercentage?: boolean
  isLoading?: boolean
  isError?: boolean
  dataLimit?: number
}

export const PieCard = ({
  data,
  total,
  headers,
  totalLabel,
  title,
  days,
  showPercentage,
  isLoading,
  isError,
  dataLimit = 10,
}: PieCardProps) => {
  const { customPalette } = useAppSelector((state) => state.user)

  const theme = useTheme()
  const { mode } = useColorScheme()
  const primaryPalette = theme.colorSchemes[mode as 'light' | 'dark'].palette.primary
  const mainPalette = customPalette
    ? Object.values(primaryPalette).slice(0, 10).reverse()
    : [blue[900], blue[800], blue[700], blue[600], blue[500], blue[400], blue[300], blue[200], blue[100], blue[50]]

  const styles: JoyStyles = {
    card: {
      borderRadius: globalStyles.bigCard.borderRadius,
      border: globalStyles.border,
    },
    chart: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      padding: 3,
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: '50%',
    },
  }

  let headersWithAverage = [...(headers ?? [])]
  if (days) headersWithAverage.splice(2, 0, 'Promedio')
  let dataWithAverage = [...(data?.slice(0, dataLimit) ?? [])]
  if (days)
    dataWithAverage = data?.map((row: any) => {
      let currentRow = [...row]
      const average = Math.round(row[1] / days)
      currentRow.splice(2, 0, average)
      return currentRow
    })

  if (showPercentage) {
    headersWithAverage.splice(2, 0, 'Porcentaje')
    const totalValue = dataWithAverage?.reduce((acc: number, row: any) => acc + row[1], 0)
    dataWithAverage = dataWithAverage?.map((row: any) => {
      let currentRow = [...row]
      const percentage = Math.round((row[1] / totalValue) * 100)
      currentRow.splice(2, 0, `${percentage}%`)
      return currentRow
    })
  }

  const pieSize = 200

  const [hoverIndex, setHoverIndex] = React.useState<number | undefined>()

  return isError ? (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      justifyItems={'center'}
      display={'flex'}
      sx={{ ...styles.card, minHeight: '350px', width: '100%', padding: 7 }}
    >
      <Stack
        alignItems={'center'}
        gap={1}
        height={pieSize}
        width={pieSize}
      >
        <ErrorOutline
          sx={{
            fontSize: 100,
          }}
        />
        <Typography level="h2">Error de carga</Typography>
        <Typography
          level="body-md-light"
          color="neutral"
        >
          El gráfico no cargó de manera correcta. Por favor cargar la página de vuelta
        </Typography>
      </Stack>
    </Box>
  ) : !(data && !data.length) ? (
    <Box sx={styles.card}>
      <Typography
        sx={{ paddingTop: 3, textAlign: 'center' }}
        level="title-lg-bold"
      >
        {title}
      </Typography>
      <Box sx={styles.chart}>
        {isLoading ? (
          <Skeleton
            variant="circular"
            width={pieSize}
            height={pieSize}
          />
        ) : (
          <Box
            onMouseMove={(e) => {
              e.persist()
              const { clientX, clientY } = e
              if (!document.elementFromPoint(clientX, clientY)?.classList.contains('MuiPieArc-root')) {
                setHoverIndex(undefined)
                return
              }
              e.target.dispatchEvent(new MouseEvent('click', { bubbles: true }))
            }}
            onMouseLeave={() => setHoverIndex(undefined)}
          >
            <PieChart
              loading
              onItemClick={(_, { dataIndex }) => setHoverIndex(dataIndex)}
              height={pieSize}
              width={pieSize}
              slotProps={{ legend: { hidden: true } }}
              series={[
                {
                  data: dataWithAverage.map((row: any, index: number) => ({
                    label: typeof row[0] === 'string' ? row[0] : row[0].label,
                    value: row[1],
                    color: mainPalette[index] as string,
                  })),
                  innerRadius: 70,
                  outerRadius: 100,
                  // paddingAngle: 1,
                  // cornerRadius: 5,
                  cx: '100%',
                  cy: '50%',
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  highlighted: { innerRadius: 60 },
                  faded: { color: 'gray' },
                },
              ]}
            >
              {false && (
                <>
                  <PieCenterLabel>{total}</PieCenterLabel>
                  <PieCenterText>{totalLabel}</PieCenterText>
                </>
              )}
            </PieChart>
          </Box>
        )}
      </Box>
      <Table size="sm">
        <thead>
          <tr>
            {headersWithAverage.map((header, index) => (
              <th
                key={index}
                style={index === 0 ? { width: `${100 / (1 + 0.12 * headersWithAverage.length)}%` } : undefined}
              >
                <Stack
                  alignItems={'center'}
                  direction="row"
                  gap={2}
                  sx={!index ? { paddingLeft: 2 } : {}}
                >
                  {!index && (
                    <AspectRatio
                      ratio={1}
                      sx={{ ...styles.dot, visibility: 'hidden' }}
                    >
                      <div
                        style={{
                          backgroundColor: mainPalette[index] as string,
                        }}
                      ></div>
                    </AspectRatio>
                  )}
                  <Typography level="body-sm-light">{header}</Typography>
                </Stack>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  {headersWithAverage.map((header, columnIndex) => (
                    <td
                      style={{ textAlign: 'left', paddingLeft: !columnIndex ? '1.25rem' : undefined }}
                      key={columnIndex}
                    >
                      <Typography>
                        <Skeleton>{!columnIndex ? 'Lorem ipsum dolor sit amet' : header}</Skeleton>
                      </Typography>
                    </td>
                  ))}
                </tr>
              ))
            : dataWithAverage.map((row: any, index: number) => (
                <tr key={index}>
                  {headersWithAverage.map((_header, columnIndex) => (
                    <td
                      style={{
                        textAlign: 'left',
                        opacity: typeof hoverIndex === 'number' && hoverIndex !== index ? 0.5 : 1,
                      }}
                      key={columnIndex}
                    >
                      {columnIndex === 0 ? (
                        <Stack
                          alignItems={'center'}
                          direction="row"
                          gap={2}
                          sx={{ paddingLeft: 2 }}
                        >
                          <AspectRatio
                            ratio={1}
                            sx={styles.dot}
                          >
                            <div
                              style={{
                                backgroundColor: mainPalette[index] as string,
                              }}
                            ></div>
                          </AspectRatio>
                          {typeof row[0] === 'string' ? (
                            <Typography level="body-sm-light">{row[0]}</Typography>
                          ) : (
                            <Stack direction="row">
                              <Typography
                                endDecorator={
                                  <IconButton
                                    color="primary"
                                    size="sm"
                                    component={'a'}
                                    href={row[0].link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <LinkIcon width={12} />
                                  </IconButton>
                                }
                                level="body-sm-light"
                              >
                                {row[0].label}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      ) : (
                        <Typography level="body-sm-light">{row[columnIndex]}</Typography>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </Table>
    </Box>
  ) : (
    <></>
  )
}

const TotalsTitle = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'text-after-edge',
  fontSize: 36,
  fontWeight: 700,
  fontFamily: 'Inter', // TODO: no need to specify this when removing bootstrap
}))

const TotalsLabel = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'text-before-edge',
  fontSize: 12,
  fontFamily: 'Inter', // TODO: no need to specify this when removing bootstrap
  fontWeight: 400,
}))

const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  const { width, height, top } = useDrawingArea()
  return (
    <TotalsTitle
      x={width}
      y={top + height / 1.75}
    >
      {children}
    </TotalsTitle>
  )
}

const PieCenterText = ({ children }: { children: React.ReactNode }) => {
  const { width, top, height } = useDrawingArea()
  return (
    <TotalsLabel
      x={width}
      y={top + height / 1.75}
    >
      {children}
    </TotalsLabel>
  )
}

interface RatingCardProps {
  rating: number
  name: string
  date: string
  comments?: string
}

export const RatingCard = ({ rating, name, date, comments }: RatingCardProps) => (
  <Stack gap={2}>
    <Stack
      justifyContent={'space-between'}
      direction="row"
    >
      <Rating
        size="small"
        defaultValue={rating}
        precision={0.5}
        readOnly
      />
      <Typography
        sx={{ color: globalStyles.secondaryTextColor }}
        level="body-sm-light"
      >
        {date}
      </Typography>
    </Stack>
    <Typography level="h4">{name}</Typography>
    <Stack>
      {splitLines(comments)?.map((line, index) => (
        <Typography
          key={index}
          level="body-md-light"
        >
          {line}
        </Typography>
      ))}
    </Stack>
  </Stack>
)
