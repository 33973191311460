import {
  useCreateOwnerNewsMutation,
  useGetOwnerNewsByIdQuery,
  useUpdateOwnerNewsMutation,
} from '@endpoints/ownersEndpoint'
import { GalleryImage, OwnerNews, PickByType } from '@types'
import { onlySavedFilesData, onlyUnique, pendingToast, updateErrorToast, updateSuccessToast } from '@utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSession } from './useSession'
import { useValidation } from './useValidation'
import { string } from 'yup'
import { useGallery } from './useGallery'

interface Props {
  id?: number
}

export const useOwnerNews = ({ id }: Props) => {
  const { client } = useSession()

  const {
    data: queriedOwnerNews,
    isLoading: isLoadingNews,
    isError: isErrorNews,
    error: errorNews,
    refetch: refetchNews,
  } = useGetOwnerNewsByIdQuery({ id: id!, clientId: client?.id! }, { skip: !id || !client })

  const [updateOwnerNews, { isLoading: isUpdatingOwnerNews }] = useUpdateOwnerNewsMutation()
  const [createOwnerNews, { isLoading: isCreatingOwnerNews }] = useCreateOwnerNewsMutation()

  const isSaving = isUpdatingOwnerNews || isCreatingOwnerNews

  const blankOwnerNews: Partial<OwnerNews> = {
    date_published: moment().format('YYYY-MM-DDTHH:mm'),
    title: '',
    headline: '',
    content: '',
    footer: '',
    is_active: false,
    featured: false,
    extra_info: {
      visualizedSteps: [0],
    },
  }

  const [ownerNews, setOwnerNews] = useState(blankOwnerNews)

  useEffect(() => {
    setOwnerNews(queriedOwnerNews ?? blankOwnerNews)
  }, [id, queriedOwnerNews])

  const handleContentChange = (contentHtml: string) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      content: contentHtml,
    }))
  }

  const deleteImage = () => {
    setOwnerNews((prevData) => ({
      ...prevData,
      image: '',
    }))
  }

  const removeFile = (index: number) => {
    setOwnerNews((prevData) => {
      let newFiles = [...(prevData?.files_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        files_data: newFiles,
      }
    })
  }

  const { uploadOwnerNewsFiles } = useGallery()

  const postOrPatch = ({ is_active }: Partial<OwnerNews>) => {
    const { image, tokko_property, ...ownerNewsRest } = ownerNews
    const data = {
      ...ownerNewsRest,
      is_active,
      ...((image instanceof File || image === '') && { image }),
      files_data: onlySavedFilesData(ownerNews.files_data),
      client: id ? ownerNewsRest.client : client?.id,
    }
    return id ? updateOwnerNews({ id, data }) : createOwnerNews({ data })
  }

  const saveOwnerNews = async (is_active: boolean) => {
    const toastId = pendingToast(toast, `${id ? 'Guardando' : 'Creando'} novedad ...`)
    return postOrPatch({ is_active })
      .unwrap()
      .then((result) => {
        const filesToUpload = ownerNews?.files_data?.filter((id) => id.file instanceof File) as GalleryImage[]
        if (filesToUpload?.length) uploadOwnerNewsFiles({ id: result.id, gallery: filesToUpload })

        updateSuccessToast(toast, toastId, `Novedad ${id ? 'editada' : 'creada'} con éxito`)
      })
      .catch((error: any) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403
            ? error?.data?.detail
            : `No se pudo ${id ? 'editar' : 'crear'} la novedad "${ownerNews.title}"`,
        )
        throw error
      })
  }

  const markVisualizedStep = (step: number) => {
    if (!ownerNews?.extra_info?.visualizedSteps?.includes(step))
      setOwnerNews((prevData) => ({
        ...prevData,
        extra_info: {
          ...prevData.extra_info,
          visualizedSteps: [...prevData?.extra_info?.visualizedSteps, step],
        },
      }))
  }

  const addOwner = (userId: number) => {
    if (ownerNews?.users?.includes(userId)) return
    setOwnerNews((prevData) => ({
      ...prevData,
      users: [...(prevData?.users ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      users: users.every((user) => prevData?.users?.includes(user))
        ? prevData?.users?.filter((id) => !users.includes(id))
        : [...(prevData?.users ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      users: prevData?.users?.filter((id) => id !== userId),
    }))
  }

  const setValue = <T>(name: keyof PickByType<OwnerNews, T>, value: T) => {
    setOwnerNews((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const { validationErrors } = useValidation({
    shape: {
      title: string().required('El título es requerido'),
      headline: string().required('El subtítulo es requerido'),
    },
    data: ownerNews,
  })

  const setImage = (files: File[] | null) => {
    if (files?.length) {
      setOwnerNews((prevData) => ({
        ...prevData,
        image: files[0],
      }))
    }
  }

  const addFiles = (files: File[] | null) => {
    if (!files) return
    setOwnerNews((prevData) => ({
      ...prevData,
      files_data: [...(prevData?.files_data ?? []), ...files.map((file) => ({ file }))],
    }))
  }

  return {
    ownerNews,
    setOwnerNews,
    isLoadingNews,
    isSaving,
    handleContentChange,
    addFiles,
    deleteImage,
    removeFile,
    saveOwnerNews,
    markVisualizedStep,
    addOwner,
    removeOwner,
    toggleOwners,
    isErrorNews,
    setValue,
    validationErrors,
    setImage,
    errorNews,
    refetchNews,
  }
}
