export const colors = {
  darkWhite: '#f5f5fa',
  lightGrey: '#b6b6b6',
  grey: '#dbdbdb',
  black: '#000000',
  blue: '#3C58D3',
  lightBlue: '#7A92EF',
  red: '#FF0000',
  secondaryText: '#676767',
  activeGreen: '#0CDB5F'
}

export const globalStyles = {
  inputHeight: 35,
  navBarHeight: 50,
  topBarHeight: 40,
  stepperHeight: 45,
  stepTitleHeight: 73,
  leftPanelWidth: 307.83,
  defaultWhite: colors.darkWhite,
  border: `1px solid var(--joy-palette-divider)`,
  borderActive: `1px solid var(--joy-palette-primary-plainColor)`,
  borderError: `1px solid var(--joy-palette-danger-outlinedColor)!important`,
  secondaryTextColor: 'var(--joy-palette-primary-plainDisabledColor)',
  borderRadius: '5px',
  clientSelectWidth: 334.48,
  notificationsIconWidth: 92.35,
  gradientColor: (_rotation?: number) =>
    `linear-gradient(to right, var(--joy-palette-primary-400), var(--joy-palette-primary-600))`,
  card: {
    borderRadius: '8px',
  },
  bigCard: {
    borderRadius: '15px',
  },
  formDrawerSlotProps: {
    content: {
      sx: {
        width: { xs: '100%', md: '900px' },
      },
    },
  },
  requiredFieldLabel: {
    '&::after': {
      content: '"*"',
      color: 'red',
      marginLeft: '5px',
    },
  },
  dashedBorder: `2px dashed ${colors.lightGrey}`,
}
