import { ValidationErrors } from '@types'
import { useState } from 'react'
import * as yup from 'yup'

interface ValidationArgs {
  shape: yup.ObjectShape
  data: {
    [key: string]: any
  }
}

export const useValidation = ({ shape, data }: ValidationArgs) => {
  const schema = yup.object(shape)
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

  schema
    .validate(data, { abortEarly: false })
    .then(() => {
      if (JSON.stringify(validationErrors) === JSON.stringify({})) return
      setValidationErrors({})
    })
    .catch((error: yup.ValidationError) => {
      const errorObject: Record<string, string> = {}
      error.inner.forEach((error) => {
        Object.assign(errorObject, { [error.path ?? '']: error.message })
      })
      if (JSON.stringify(validationErrors) === JSON.stringify(errorObject)) return
      setValidationErrors(errorObject)
    })

  return { validationErrors }
}
