import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, SiteDevelopment } from '@types'

const developmentsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSiteDevelopments: builder.query<
      SiteDevelopment[],
      { client: number; search?: string; ids?: number[]; is_active?: boolean }
    >({
      query: ({
        client,
        search,
        ids,
        is_active,
      }: {
        client: number
        search?: string
        ids?: number[]
        is_active?: boolean
      }) => ({
        url: 'site/desarrollos/',
        params: Object.assign(
          {
            client,
          },
          ids ? { ids } : {},
          search ? { search } : {},
          is_active ? { is_active } : {},
        ),
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'SiteDevelopment' as const, id })), 'SiteDevelopment']
          : ['SiteDevelopment'],
    }),
    getSiteDevelopmentById: builder.query<SiteDevelopment, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('site/desarrollos'),
      providesTags: (_result, _error, { id }) => [{ type: 'SiteDevelopment', id }],
    }),
    deleteSiteDevelopment: builder.mutation<void, { id: number; client: number }>({
      query: deleteByIdMutation('site/desarrollos'),
      invalidatesTags: ['Property', 'ProductTable'],
      async onQueryStarted({ id, client }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            developmentsApi.util.updateQueryData('getSiteDevelopments', { client }, (draftDevelopments) => {
              return draftDevelopments.filter((draftDevelopment) => draftDevelopment.id !== id)
            }),
          )
        } catch {}
      },
    }),
    createSiteDevelopment: builder.mutation<SiteDevelopment, { data: Partial<SiteDevelopment> }>({
      query: createOneMutation('site/desarrollos'),
      invalidatesTags: ['Property', 'ProductTable'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdDevelopment } = await queryFulfilled
          dispatch(
            developmentsApi.util.updateQueryData(
              'getSiteDevelopments',
              { client: createdDevelopment.client },
              (draftDevelopments) => {
                draftDevelopments.unshift(createdDevelopment)
                return draftDevelopments
              },
            ),
          )
        } catch {}
      },
    }),
    updateSiteDevelopment: builder.mutation<SiteDevelopment, { id: number; data: Partial<SiteDevelopment> }>({
      query: updateByIdMutation('site/desarrollos'),
      invalidatesTags: ['Property', 'ProductTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDevelopment } = await queryFulfilled
          dispatch(
            developmentsApi.util.updateQueryData(
              'getSiteDevelopmentById',
              { id, clientId: updatedDevelopment.client },
              (draft) => {
                Object.assign(draft, updatedDevelopment)
              },
            ),
          )
          dispatch(
            developmentsApi.util.updateQueryData(
              'getSiteDevelopments',
              { client: updatedDevelopment.client },
              (draftDevelopments) => {
                return draftDevelopments.map((draftDevelopment) =>
                  draftDevelopment.id !== id ? draftDevelopment : updatedDevelopment,
                )
              },
            ),
          )
        } catch {}
      },
    }),
    uploadSiteDevelopmentImages: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/desarrollos', 'galeria'),
      invalidatesTags: ['SiteDevelopment', 'ProductTable'],
    }),
    uploadSiteDevelopmentBlueprints: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/desarrollos', 'galeria_planos'),
      invalidatesTags: ['SiteDevelopment', 'ProductTable'],
    }),
    uploadSiteDevelopmentFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/desarrollos', 'galeria_archivos'),
      invalidatesTags: ['SiteDevelopment', 'ProductTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetSiteDevelopmentsQuery,
  useGetSiteDevelopmentByIdQuery,
  useDeleteSiteDevelopmentMutation,
  useCreateSiteDevelopmentMutation,
  useUpdateSiteDevelopmentMutation,
  usePrefetch: usePrefetchSiteDevelopments,
  useUploadSiteDevelopmentImagesMutation,
  useUploadSiteDevelopmentBlueprintsMutation,
  useUploadSiteDevelopmentFilesMutation,
} = developmentsApi
