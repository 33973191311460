import {
  useCreateInvestorNewsMutation,
  useGetInvestorNewsByIdQuery,
  useUpdateInvestorNewsMutation,
} from '@endpoints/investorEndpoint'
import { ChangeEvent, useEffect, useState } from 'react'
import { GalleryImage, InvestorNews, PickByType } from '@types'
import moment from 'moment'
import { onlySavedFilesData, onlyUnique, pendingToast, reorder, updateErrorToast, updateSuccessToast } from '@utils'
import { useSession } from './useSession'
import { toast } from 'react-toastify'
import { useValidation } from './useValidation'
import { string } from 'yup'
import { useGallery } from './useGallery'

interface UseInvestorNews {
  id?: number
}

interface ImageGallery {
  id?: string
  file?: File | string
  text_alt?: string
}

export const useInvestorNews = ({ id }: UseInvestorNews) => {
  const { user, client } = useSession()
  const loggedUserName = `${user?.first_name} ${user?.last_name}`

  const {
    data: queriedNews,
    isFetching: isFetchingNews,
    isLoading: isLoadingNews,
    isError: isErrorNews,
    error: errorNews,
    refetch: refetchNews,
  } = useGetInvestorNewsByIdQuery({ id: id!, clientId: client?.id! }, { skip: !client || !id })
  const [updateInvestorsNews, { isLoading: isUpdating }] = useUpdateInvestorNewsMutation()
  const [createInvestorNews, { isLoading: isCreating }] = useCreateInvestorNewsMutation()

  const [investorNews, setInvestorNews] = useState<Partial<InvestorNews>>({ extra_info: { visualizedSteps: [0] } })

  const { validationErrors } = useValidation({
    shape: {
      title: string().required('Ingrese un título'),
      headline: string().required('Ingrese un subtítulo'),
      // image: mixed().test('required', 'La portada es requerida', (value) => {
      //   return value instanceof File || (value !== '' && typeof value === 'string')
      // }),
    },
    data: investorNews,
  })

  const setCoverImage = (files: File[] | null) => {
    if (files?.length) {
      setInvestorNews((prevData) => ({
        ...prevData,
        image: files[0],
      }))
      // !fieldsChanged.includes('Portada') && setFieldsChanged((prevValues) => [...prevValues, 'Portada'])
    }
  }

  const addFiles = (files: File[] | null) => {
    if (!files) return
    setInvestorNews((prevData) => ({
      ...prevData,
      files_data: [...(prevData?.files_data ?? []), ...files.map((file) => ({ file }))],
    }))
  }

  const reorderFiles = (prevIndex: number, newIndex: number) =>
    setInvestorNews((prevData) => {
      if (!prevData?.files_data) return prevData
      return {
        ...prevData,
        files_data: reorder(prevData?.files_data, prevIndex, newIndex),
      }
    })

  const deleteFile = (index: number) =>
    setInvestorNews((prevData) => {
      let newFiles = [...(prevData?.files_data ?? [])]
      newFiles.splice(index, 1)
      return {
        ...prevData,
        files_data: newFiles,
      }
    })

  const deleteImage = (index: number) => {
    setInvestorNews((prevData) => {
      return {
        ...prevData,
        images_data: prevData.images_data?.filter((_, i) => i !== index),
      }
    })
  }

  const setImageText = (index: number, text: string) => {
    setInvestorNews((prevData) => {
      const newFiles = [...(prevData?.images_data ?? [])]
      newFiles[index] = { ...newFiles[index], text_alt: text }
      return {
        ...prevData,
        images_data: newFiles,
      }
    })
  }

  const addInvestor = (userId: number) => {
    if (investorNews?.users?.includes(userId)) return
    setInvestorNews((prevData) => ({
      ...prevData,
      users: [...(prevData?.users ?? []), userId],
    }))
  }

  const toggleInvestors = (users: number[]) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      users: users.every((user) => prevData?.users?.includes(user))
        ? prevData?.users?.filter((id) => !users.includes(id))
        : [...(prevData?.users ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeInvestor = (userId: number) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      users: prevData?.users?.filter((id) => id !== userId),
    }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist?.()
    setInvestorNews((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const addImages = (images: File[] | null) => {
    if (!images) return
    setInvestorNews((prevData) => ({
      ...prevData,
      images_data: [
        ...(prevData?.images_data ?? []),
        ...images.map((file) => ({
          file,
          text_alt: '',
        })),
      ],
    }))
  }

  const reorderImages = (prevIndex: number, newIndex: number) =>
    setInvestorNews((prevData) => {
      if (!prevData?.images_data) return prevData
      return {
        ...prevData,
        images_data: reorder(prevData?.images_data, prevIndex, newIndex),
      }
    })

  const removeKeyFromArray = (objectArray: any[], key: string) => {
    return objectArray.map((object) => {
      let newObject = { ...object }
      delete newObject[key as keyof typeof newObject]
      return newObject
    })
  }

  const removeKey = (object: any, key: string) => {
    let newObject = { ...object }
    delete newObject[key as keyof typeof newObject]
    return newObject
  }

  const uploadGallery = (result: InvestorNews, gallery: ImageGallery[], index: number, length: number) => {
    toast.promise(
      updateInvestorsNews({
        id: result.id!,
        data: {
          ...removeKey(result, 'image'), //
          files: [gallery[0].file as File],
          files_data: removeKeyFromArray(result.files_data, 'file'),
          images_data: [
            ...removeKeyFromArray(result.images_data, 'file'),
            { file_name: (gallery[0].file as File).name, text_alt: gallery[0].text_alt },
          ],
        },
      })
        .unwrap()
        .then((successResult) => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(successResult, nextGallery, index + 1, length)
        })
        .catch(() => {
          let nextGallery = gallery
          nextGallery.shift()
          if (nextGallery.length) uploadGallery(result, nextGallery, index + 1, length)
        }),
      {
        pending: `Guardando imagen ${index} de ${length} ...`,
        success: `Imagen ${index} de ${length} guardada con éxito`,
        error: `No se pudo guardar la imagen "${index} de ${length} `,
      },
    )
  }

  // Order is mandatory because of the parallel upload
  const ordered_images_data = investorNews?.images_data?.map((image, index) => ({ ...image, order: index }))
  const ordered_files_data = investorNews?.files_data?.map((file, index) => ({ ...file, order: index }))

  const postOrPatch = ({ is_active }: Partial<InvestorNews>) => {
    const { image, ...investorNewsRest } = investorNews
    const data = {
      ...investorNewsRest,
      client: id ? investorNews?.client : client?.id,
      users: investorNews?.prerelease ? [] : investorNews?.users,
      files_data: onlySavedFilesData(ordered_files_data),
      images_data: onlySavedFilesData(ordered_images_data),
      is_active: is_active ?? investorNews?.is_active,
      ...((investorNews.image instanceof File || investorNews.image === '') && { image: investorNews.image }),
    }

    return id ? updateInvestorsNews({ id, data }) : createInvestorNews({ data })
  }

  const { uploadInvestorNewsImages, uploadInvestorNewsFiles } = useGallery()

  const saveNews = (is_active?: boolean) => {
    const toastId = pendingToast(toast, `${id ? 'Guardando' : 'Creando'} novedad "${investorNews.title}" ...`)

    return postOrPatch({ is_active: is_active ?? false })
      .unwrap()
      .then((result) => {
        const imagesToUpload = ordered_images_data?.filter((id) => id.file instanceof File) as GalleryImage[]
        if (imagesToUpload?.length) uploadInvestorNewsImages({ id: result.id, gallery: imagesToUpload })

        const filesToUpload = ordered_files_data?.filter((id) => id.file instanceof File) as GalleryImage[]
        if (filesToUpload?.length) uploadInvestorNewsFiles({ id: result.id, gallery: filesToUpload })

        updateSuccessToast(toast, toastId, `Novedad "${investorNews.title}" ${id ? 'editada' : 'creada'} con éxito`)
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403
            ? error?.data?.detail
            : `No se pudo ${id ? 'editar' : 'crear'} la novedad "${investorNews.title}"`,
        )
        throw error
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403
            ? error?.data?.detail
            : `No se pudo ${id ? 'editar' : 'crear'} la novedad "${investorNews.title}"`,
        )
        throw error
      })
  }

  const emptyInvestorNews = {
    date_published: moment().format('YYYY-MM-DDThh:mm'),
    title: '',
    headline: '',
    content: '',
    footer: '',
    is_active: false,
    prerelease: false,
    files: [],
    files_data: [],
    images_data: [],
    users: [],
    author: loggedUserName,
    author_choices: 'user',
    extra_info: {
      visualizedSteps: [0],
    },
    developments: [],
  }

  const markVisualizedStep = (step: number) => {
    if (!investorNews?.extra_info?.visualizedSteps?.includes(step))
      setInvestorNews((prevData) => ({
        ...prevData,
        extra_info: {
          visualizedSteps: [...(prevData?.extra_info?.visualizedSteps ?? []), step],
        },
      }))
  }

  useEffect(() => {
    setInvestorNews(queriedNews ?? emptyInvestorNews)
  }, [queriedNews])

  const isSaving = isUpdating || isCreating

  const setValue = <T>(name: keyof PickByType<InvestorNews, T>, value: T) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const toggleDevelopment = ({ id, name, investors }: { id: number; name: string; investors?: number[] }) => {
    setInvestorNews((prevData) => ({
      ...prevData,
      developments: prevData?.developments?.some((d) => d.id === id)
        ? prevData?.developments?.filter((d) => d.id !== id)
        : [...(prevData?.developments ?? []), { id, name }],
      ...(investors && { users: [...(prevData?.users ?? []), ...investors].filter(onlyUnique) }),
    }))
  }

  return {
    investorNews, //
    isLoadingNews,
    isErrorNews,
    setCoverImage,
    deleteFile,
    deleteImage,
    handleChange,
    addImages,
    reorderImages,
    setImageText,
    validationErrors,
    saveNews,
    isSaving,
    markVisualizedStep,
    addInvestor,
    removeInvestor,
    isFetchingNews,
    toggleInvestors,
    setValue,
    toggleDevelopment,
    addFiles,
    refetchNews,
    errorNews,
    reorderFiles,
  }
}
