import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, Property } from '@types'

const propertiesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyById: builder.query<Property, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('site/propiedades'),
      providesTags: (_result, _error, { id }) => [{ type: 'Property', id }],
    }),
    deleteProperty: builder.mutation<void, { id: number; client: number }>({
      query: deleteByIdMutation('site/propiedades'),
      invalidatesTags: ['SiteDevelopment', 'PropertyTable', 'PropertyMixTable', 'ProductTable'],
    }),
    createProperty: builder.mutation<Property, { data: Partial<Property> }>({
      query: createOneMutation('site/propiedades'),
      invalidatesTags: ['SiteDevelopment', 'PropertyTable', 'PropertyMixTable', 'ProductTable'],
    }),
    updateProperty: builder.mutation<Property, { id: number; data: Partial<Property> }>({
      query: updateByIdMutation('site/propiedades'),
      invalidatesTags: ['SiteDevelopment', 'PropertyTable', 'PropertyMixTable', 'ProductTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProperty } = await queryFulfilled
          dispatch(
            propertiesApi.util.updateQueryData('getPropertyById', { id, clientId: updatedProperty.client }, (draft) => {
              Object.assign(draft, updatedProperty)
            }),
          )
        } catch {}
      },
    }),
    uploadPropertyImages: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/propiedades', 'galeria'),
      invalidatesTags: ['Property', 'ProductTable'],
    }),
    uploadPropertyBlueprints: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/propiedades', 'galeria_planos'),
      invalidatesTags: ['Property', 'ProductTable'],
    }),
    uploadPropertyFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('site/propiedades', 'galeria_archivos'),
      invalidatesTags: ['Property', 'ProductTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPropertyByIdQuery,
  useLazyGetPropertyByIdQuery,
  useDeletePropertyMutation,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  usePrefetch: usePrefetchProperties,
  useUploadPropertyImagesMutation,
  useUploadPropertyBlueprintsMutation,
  useUploadPropertyFilesMutation,
} = propertiesApi
