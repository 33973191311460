import { external_websites_inquiries_options, inquiries_options } from '@constants'
import { useCreateReportMutation, useGetReportByIdQuery, useUpdateReportMutation } from '@endpoints/reportsEndpoint'
import { useGetVisitRecordTableQuery } from '@endpoints/tablesEndpoint'
import { GenericProperty, PickByType, Report, VisitRecordRow } from '@types'
import { onlyUnique, pendingToast, updateErrorToast, updateSuccessToast } from '@utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSession } from './useSession'
import { useValidation } from './useValidation'
import { lazy, number, string } from 'yup'

interface Props {
  id?: number
}

export const useReport = ({ id }: Props) => {
  const { client } = useSession()

  const {
    data: queriedReport,
    isLoading: isLoadingQueriedReport,
    isError: isErrorQueriedReport,
    isFetching: _isFetchingReport,
    error: errorReport,
    refetch: refetchReport,
  } = useGetReportByIdQuery({ id: id!, clientId: client?.id! }, { skip: !client || !id })

  const { data: visitRecordQuery } = useGetVisitRecordTableQuery({ clientId: client?.id }, { skip: !client })

  const [updateReport, { isLoading: isUpdatingReport }] = useUpdateReportMutation()
  const [createReport, { isLoading: isCreatingReport }] = useCreateReportMutation()

  const blankReport: Partial<Report> = {
    content: {
      visualizedSteps: [0],
      inquiries: [{ source: inquiries_options[0], count: 0 }],
      external_websites_inquiries: [{ source: external_websites_inquiries_options[0], count: 0, visits_count: 0 }],
      fake_address: '',
      real_address: '',
    },
    is_active: false,
    visits: [],
    date_from: moment().format('YYYY-MM-DDThh:mm'),
    date_to: moment().format('YYYY-MM-DDThh:mm'),
    footnotes: '',
  }
  const [report, setReport] = useState(blankReport)

  const visitsDetail = visitRecordQuery?.results?.filter((visit) => report.visits?.includes(visit.id)) ?? [] // TODO agregar filtro del back o devolver detallada

  useEffect(() => {
    if (queriedReport) {
      setReport(queriedReport ?? blankReport)
    }
  }, [queriedReport])

  const isLoadingReport = isLoadingQueriedReport
  const isErrorReport = isErrorQueriedReport

  const filteredByPeriodVisits = (visits: VisitRecordRow[]) => {
    if (visits) {
      return visits.filter(
        (visit) =>
          moment(visit.date_visit).format('YYYY-MM-DD') >= moment(report.date_from).format('YYYY-MM-DD') &&
          moment(visit.date_visit).format('YYYY-MM-DD') <= moment(report.date_to).format('YYYY-MM-DD'),
      )
    }
    return []
  }
  const handleChangeProperty = (selectedProperty?: GenericProperty) => {
    const periodVisits = filteredByPeriodVisits(visitRecordQuery?.results ?? [])
    const visitsIds = periodVisits.map((visit) => visit.id)
    setReport((prevData) => ({
      ...prevData,
      property: Number(selectedProperty?.id),
      owners: selectedProperty?.owners ?? [],
      visits: visitsIds,
      content: {
        ...prevData.content,
        fake_address: selectedProperty?.address ?? '',
        real_address: selectedProperty?.address ?? '',
      },
    }))
  }

  const setInquirySource = (index: number, value: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.inquiries![index].source = value
      return newData
    })
  }

  const setExternalInquirySource = (index: number, value: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.external_websites_inquiries![index].source = value
      return newData
    })
  }

  const setInquiryOtherSource = (index: number, value: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.inquiries![index].other_source = value
      return newData
    })
  }

  const setExternalInquiryOtherSource = (index: number, value: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.external_websites_inquiries![index].other_source = value
      return newData
    })
  }

  const setInquiryCount = (index: number, value: number) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.inquiries![index].count = value
      return newData
    })
  }

  const setExternalInquiryCount = (index: number, value: number) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.external_websites_inquiries![index].count = value
      return newData
    })
  }

  const setExternalInquiryVisitsCount = (index: number, value: number) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.external_websites_inquiries![index].visits_count = value
      return newData
    })
  }

  const setExternalInquiryUrl = (index: number, value: string) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.content!.external_websites_inquiries![index].url = value
      return newData
    })
  }

  const addInquiry = () => {
    setReport((prevData) => {
      return {
        ...prevData,
        content: {
          ...prevData.content,
          inquiries: [...(prevData.content?.inquiries ?? []), { source: inquiries_options[0], count: 0 }],
        },
      }
    })
  }

  const addExternalInquiry = () => {
    setReport((prevData) => {
      return {
        ...prevData,
        content: {
          ...prevData.content,
          external_websites_inquiries: [
            ...(prevData.content?.external_websites_inquiries ?? []),
            { source: external_websites_inquiries_options[0], count: 0, visits_count: 0, url: '' },
          ],
        },
      }
    })
  }

  const removeInquiry = (index: number) => {
    setReport((prevData) => {
      return {
        ...prevData,
        content: {
          ...prevData.content,
          inquiries: prevData.content?.inquiries?.filter((_, i) => i !== index),
        },
      }
    })
  }

  const removeExternalInquiry = (index: number) => {
    setReport((prevData) => {
      return {
        ...prevData,
        content: {
          ...prevData.content,
          external_websites_inquiries: prevData.content?.external_websites_inquiries?.filter((_, i) => i !== index),
        },
      }
    })
  }

  const postOrPatch = ({ is_active }: Partial<Report>) => {
    const data = {
      ...report,
      is_active,
      client: id ? report.client : client?.id,
    }
    return id ? updateReport({ id, data }) : createReport({ data })
  }

  const saveReport = async (is_active: boolean) => {
    const toastId = pendingToast(toast, id ? 'Guardando reporte ...' : 'Creando reporte ...')

    return postOrPatch({ is_active })
      .unwrap()
      .then(() => {
        updateSuccessToast(toast, toastId, `Reporte ${id ? 'editado' : 'creado'} con éxito`)
      })
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo ${id ? 'editar' : 'crear'} el reporte`,
        )
        throw error
      })
  }

  const markVisualizedStep = (step: number) => {
    if (!report?.content?.visualizedSteps?.includes(step))
      setReport((prevData) => ({
        ...prevData,
        content: {
          ...prevData.content,
          visualizedSteps: [...(prevData?.content?.visualizedSteps ?? []), step],
        },
      }))
  }

  const addOwner = (userId: number) => {
    if (report?.owners?.includes(userId)) return
    setReport((prevData) => ({
      ...prevData,
      owners: [...(prevData?.owners ?? []), userId],
    }))
  }

  const toggleOwners = (users: number[]) => {
    setReport((prevData) => ({
      ...prevData,
      owners: users.every((user) => prevData?.owners?.includes(user))
        ? prevData?.owners?.filter((id) => !users.includes(id))
        : [...(prevData?.owners ?? []), ...users].filter(onlyUnique),
    }))
  }

  const removeOwner = (userId: number) => {
    setReport((prevData) => ({
      ...prevData,
      owners: prevData?.owners?.filter((id) => id !== userId),
    }))
  }

  const removeProperty = () => {
    setReport((prevData) => ({
      ...prevData,
      property: undefined,
    }))
  }

  const toggleVisit = (visitId: number) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.visits = prevData.visits?.includes(visitId)
        ? prevData.visits.filter((id) => id !== visitId)
        : [...(prevData.visits ?? []), visitId]
      return newData
    })
  }

  const toggleVisits = (visits: number[]) => {
    setReport((prevData) => {
      const newData = structuredClone(prevData)
      newData.visits = visits.every((visit) => prevData.visits?.includes(visit))
        ? prevData.visits?.filter((id) => !visits.includes(id))
        : [...(prevData.visits ?? []), ...visits].filter(onlyUnique)
      return newData
    })
  }

  const setValue = <T>(name: keyof PickByType<Report, T>, value: T) => {
    setReport((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const setContentValue = <T>(name: keyof PickByType<Report['content'], T>, value: T) => {
    setReport((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        [name]: value,
      },
    }))
  }

  const { validationErrors } = useValidation({
    shape: {
      title: string().required('El título es requerido'),
      property: lazy((value) => {
        if (value === '') {
          return string().required('Debe seleccionar una propiedad')
        }
        return number().required('Debe seleccionar una propiedad')
      }),
    },
    data: report,
  })

  const isSaving = isCreatingReport || isUpdatingReport

  return {
    report,
    isLoadingReport,
    isErrorReport,
    errorReport,
    refetchReport,
    handleChangeProperty,
    removeInquiry,
    addInquiry,
    isSaving,
    saveReport,
    markVisualizedStep,
    addOwner,
    toggleOwners,
    removeOwner,
    removeProperty,
    toggleVisit,
    toggleVisits,
    visitsDetail,
    setValue,
    validationErrors,
    setInquirySource,
    setInquiryOtherSource,
    setInquiryCount,
    setExternalInquirySource,
    setExternalInquiryOtherSource,
    setExternalInquiryCount,
    addExternalInquiry,
    removeExternalInquiry,
    setExternalInquiryUrl,
    setExternalInquiryVisitsCount,
    setContentValue,
  }
}
