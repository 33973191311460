import { Box, Button, IconButton, Skeleton, Stack, Table, Typography } from '@mui/joy'
import { globalStyles } from '@styles/styles'
import React, { ComponentProps } from 'react'
import { FieldSet } from './FieldSet'

interface TableSkeletonProps {
  rows?: number
}

export const TableSkeleton = ({ rows = 40 }: TableSkeletonProps) => {
  const cellSkeleton = (
    <Box sx={{ paddingX: 2 }}>
      <Skeleton
        variant="rectangular"
        height="1.5em"
      />
    </Box>
  )

  return (
    <>
      {/* <Stack
      direction="row"
      justifyContent="end"
      gap={2}
      sx={{
        paddingX: 4,
      }}
    >
      <Typography>
        <Skeleton>Seleccionados: 0</Skeleton>
      </Typography>
      <Typography>
        <Skeleton>Lorem ipsun dolor sit amet</Skeleton>
      </Typography>
    </Stack>
    <Divider /> */}
      <Table stickyHeader>
        <thead>
          <tr>
            <th>{cellSkeleton}</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(rows)].map((_, index) => (
            <tr key={index}>
              <td>{cellSkeleton}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export const NotificationsCardSkeleton = (props: ComponentProps<typeof Stack>) => {
  return (
    <Stack
      gap={1}
      {...props}
      sx={{
        width: 366.83,
        borderRadius: globalStyles.card.borderRadius,
        marginBottom: 2,
        border: globalStyles.border,
        p: 2,
        ...props.sx,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography level="h3">
          <Skeleton>Lorem ipsum dolor sit amet</Skeleton>
        </Typography>
        <Stack
          direction="row"
          gap={1}
        >
          <IconButton>
            <Skeleton />
          </IconButton>
          <IconButton>
            <Skeleton />
          </IconButton>
        </Stack>
      </Stack>
      <Typography level="h3">
        <Skeleton>Lorem ipsum dolor sit amet</Skeleton>
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        <Typography level="h3">
          <Skeleton>Lorem ipsum </Skeleton>
        </Typography>
        <Typography level="h3">
          <Skeleton>Lorem ipsum dolor</Skeleton>
        </Typography>
      </Stack>
    </Stack>
  )
}

export const SiteTemplateCardSkeleton = (props: ComponentProps<typeof Stack>) => {
  return (
    <FieldSet>
      <Stack
        gap={2}
        {...props}
        sx={{
          padding: 2,
          border: globalStyles.border,
          borderRadius: globalStyles.card.borderRadius,
          ...props.sx,
        }}
      >
        <Typography level="h3">
          <Skeleton>Lorem ipsum dolor sit amet</Skeleton>
        </Typography>
        <Typography level="h3">
          <Skeleton>Lorem ipsum dolor sit amet</Skeleton>
        </Typography>
        <Stack
          direction="row"
          gap={2}
        >
          <Button>
            <Skeleton />
            Editar
          </Button>
          <Button>
            <Skeleton />
            Eliminar
          </Button>
        </Stack>
      </Stack>
    </FieldSet>
  )
}

export const PreviewHeaderSkeleton = () => {
  return (
    <Stack
      sx={{ minHeight: 2.5 * globalStyles.navBarHeight, padding: 3 }}
      width={'100%'}
    >
      <Typography level="body-md-light">
        <Skeleton>Lorem ipsum dolor </Skeleton>
      </Typography>
      <Typography
        level="h2"
        sx={{ my: 2 }}
      >
        <Skeleton>Lorem ipsum dolor</Skeleton>
      </Typography>
      <Typography level="h4">
        <Skeleton>Lorem ipsum dolor sit amet</Skeleton>
      </Typography>
      {
        <Typography level="body-md-light">
          <Skeleton>Lorem ipsum </Skeleton>
        </Typography>
      }
    </Stack>
  )
}
