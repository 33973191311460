import React, { createContext } from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface Props {
  children: JSX.Element
}

export const AlertContext = createContext<{}>({})

const AlertContextWrapper = ({ children }: Props) => {
  return (
    <AlertContext.Provider value={{}}>
      {children}
      <ToastContainer
        position="bottom-right"
        transition={Slide}
        autoClose={3000}
        theme="dark"
      />
    </AlertContext.Provider>
  )
}

export default AlertContextWrapper
