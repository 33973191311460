import React, { useEffect, useState } from 'react'
import { auth } from '@firebaseUtils/firebase'
import { signOut } from 'firebase/auth'
import { Link, navigate } from 'gatsby'
import ClientSelect from '@components/ClientSelect'
import { mediacoreApi } from '@apis/mediacoreApi'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import {
  useDeleteNotificationMutation,
  useDeleteNotificationsMutation,
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from '@endpoints/notificationsEndpoint '
import Badge from '@mui/joy/Badge'
import Drawer from '@mui/joy/Drawer'
import Box from '@mui/material/Box'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import { globalStyles } from '@styles/styles'
import Avatar from '@mui/joy/Avatar'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  IconButton,
  Input,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/joy'
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles'
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles'
import { changeUserColor, toggleGradientComponents, toggleCustomPalette, setClient } from '@slices/userSlice'
import { KeyboardArrowDown, MarkEmailUnreadOutlined, DraftsOutlined, Close } from '@mui/icons-material'
import { useSession } from '@hooks/useSession'
import NotificationsSettings from '@components/NotificationsSettings'
import MyProfile from '@components/MyProfile'
import { DrawerTitle } from '@components/tables/UserCommunicationsTable'
import {
  BellIcon,
  BellAlertIcon,
  TrashIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  PowerIcon,
  BriefcaseIcon,
  MoonIcon,
} from '@heroicons/react/24/outline'
import { Module } from '@types'
import { NotificationsCardSkeleton } from './Skeletons'
import { routes } from '@constants'

const isBrowser = typeof window !== 'undefined'

interface DrawerState {
  open: boolean
  id?: number
}

interface Props {
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const TopNavBar = ({ setShowMobileMenu }: Props) => {
  const { client, user } = useSession()
  const userState = useAppSelector((state) => state.user)

  const [notificationsDrawer, setNotificationsDrawer] = useState<DrawerState>({ open: false })
  const [myProfileDrawer, setMyProfileDrawer] = useState<DrawerState>({ open: false })

  const { data: notificationsCount } = useGetNotificationsCountQuery(undefined, {
    skip: !user,
    pollingInterval: 30000,
  })

  const newNotificationsCount = notificationsCount?.find((count) => count.client === client?.id)?.count ?? 0
  const [showOverlay, setShowOverlay] = useState(false)
  const [showClientMenu, setShowClientMenu] = useState(false)

  const titleHeight = globalStyles.stepTitleHeight
  const topBarHeight = globalStyles.navBarHeight
  const barsHeight = titleHeight + topBarHeight
  const contentHeight = `calc(100vh - ${barsHeight}px)`

  const styles = {
    navBar: {
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
      // backgroundColor: globalStyles.defaultWhite,
      position: 'sticky',
      top: 0,
      zIndex: 1020,
    },
    avatar: {
      background: userState.gradientComponents ? globalStyles.gradientColor() : 'var(--joy-palette-primary-solidBg)',
      color: 'white',
    },
    homeIcon: {
      display: { xs: 'none', sm: 'flex' },
      width: 92.35,
      borderRight: globalStyles.border,
    },
    notificationsIcon: {
      width: globalStyles.notificationsIconWidth,
      borderRight: globalStyles.border,
      borderLeft: globalStyles.border,
    },
    moduleSelect: {
      display: { xs: 'none', sm: 'flex' },
      width: globalStyles.leftPanelWidth,
      borderRight: globalStyles.border,
    },
    clientSelect: {
      width: globalStyles.clientSelectWidth,
    },

    titleBar: {
      height: titleHeight,
      padding: 3,
    },
    title: {
      paddingX: 3,
      height: topBarHeight,
      borderBottom: globalStyles.border,
    },
    modalClose: {
      position: 'relative',
      right: 0,
      top: 0,
    },
    formContainer: {
      overflowY: 'auto',
      height: contentHeight,
    },
  }
  const [dropdownLabels, setDropdownLabels] = useState<{ label: string; path: string }[]>([])

  useEffect(() => {
    setDropdownLabels([
      {
        label: 'MediaBlog',
        path: '/mediablog',
      },
      {
        label: 'MediaInvestor',
        path: '/mediainvestor',
      },
      {
        label: 'MediaOwner',
        path: '/mediaowner',
      },
      {
        label: 'MediaTracker',
        path: '/mediatracker',
      },
      {
        label: 'MediaPush',
        path: '/mediapush',
      },
      {
        label: 'MediaJobs',
        path: '/mediajobs',
      },
      {
        label: 'MediaMarketing',
        path: '/mediamarketing',
      },
    ])
  }, [])

  const { isInvestor, isOwner } = useSession()

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.navBar}
      >
        <Stack
          direction="row"
          sx={{ height: styles.navBar.height }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={styles.moduleSelect}
          >
            {isInvestor || isOwner ? (
              <img
                style={{ objectFit: 'contain' }}
                src={client?.logo as string}
                alt="client_logo"
              />
            ) : (
              <Button
                variant="plain"
                endDecorator={<KeyboardArrowDown sx={{ color: 'var(--joy-palette-text-primary)' }} />}
                onClick={() => setShowMobileMenu((value) => !value)}
              >
                <Typography
                  level="h4"
                  fontWeight="400"
                >
                  {isBrowser
                    ? dropdownLabels.find(({ path }) => location?.pathname?.startsWith(path))?.label ?? 'Inicio'
                    : 'Inicio'}
                </Typography>
              </Button>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ height: styles.navBar.height }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ ...styles.notificationsIcon }}
          >
            <Button
              variant="plain"
              sx={{ color: globalStyles.secondaryTextColor }}
              onClick={() => setShowOverlay((value) => !value)}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color="danger"
                size="sm"
                badgeContent={newNotificationsCount}
              >
                {newNotificationsCount ? (
                  <BellAlertIcon
                    style={{
                      width: '1.5em',
                    }}
                  />
                ) : (
                  <BellIcon
                    style={{
                      width: '1.5em',
                    }}
                  />
                )}
              </Badge>
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ ...styles.clientSelect }}
          >
            <Button
              startDecorator={
                <Avatar sx={styles.avatar}>
                  {client?.name.slice(0, 1)}
                  <Skeleton loading={!client?.id} />
                </Avatar>
              }
              endDecorator={<KeyboardArrowDown />}
              variant="plain"
              onClick={() => setShowClientMenu(true)}
            >
              <Typography
                level="h4"
                color="neutral"
                fontWeight="400"
              >
                <Skeleton loading={!client?.id}>{client?.name ?? 'Lorem ipsum dolor sit'}</Skeleton>
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Drawer
        anchor="right"
        open={showOverlay}
        onClose={() => setShowOverlay(false)}
        slotProps={{
          content: {
            style: { width: globalStyles.clientSelectWidth + globalStyles.notificationsIconWidth },
          },
        }}
      >
        {showOverlay && <Notifications />}
      </Drawer>
      <Drawer
        anchor="right"
        open={showClientMenu}
        onClose={() => setShowClientMenu(false)}
        slotProps={{ content: { sx: { width: { xs: '100%', md: globalStyles.clientSelectWidth } } } }}
      >
        {showClientMenu && (
          <UserMenu
            onShowMyProfile={() => {
              setMyProfileDrawer({ open: true }), setShowClientMenu(false)
            }}
            onShowNotifications={() => {
              setNotificationsDrawer({ open: true }), setShowClientMenu(false)
            }}
          />
        )}
      </Drawer>
      <Drawer
        anchor="right"
        open={notificationsDrawer.open}
        onClose={() => setNotificationsDrawer({ open: false })}
        slotProps={globalStyles.formDrawerSlotProps}
      >
        <DrawerTitle title="Notificaciones" />
        <Stack
          id="titleBar"
          sx={styles.titleBar}
        >
          <Typography
            level="h2"
            color="primary"
          >
            Configurá tus notificaciones
          </Typography>
          <Typography level="body-md-light">
            Seleccioná los medios por los cuales quieras recibir tus notificaciones de la plataforma.
          </Typography>
        </Stack>
        <Box
          sx={styles.formContainer}
          key={notificationsDrawer.id}
        >
          {Boolean(notificationsDrawer.open) && <NotificationsSettings />}
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={myProfileDrawer.open}
        onClose={() => setMyProfileDrawer({ open: false })}
        slotProps={globalStyles.formDrawerSlotProps}
      >
        {Boolean(myProfileDrawer.open) && <MyProfile />}
      </Drawer>
    </>
  )
}

export default TopNavBar

const Notifications = () => {
  const styles = {
    notificationsDrawer: {
      box: {
        paddingX: 4,
        pb: 3,
      },
      card: {
        width: 366.83,
        borderRadius: '8px',
        marginBottom: 2,
        border: globalStyles.border,
        p: 2,
      },
    },
  }

  const { client, user } = useSession()
  const [updateNotification, { isLoading: _isUpdating }] = useUpdateNotificationMutation()
  const [deleteNotification, { isLoading: _isDeleting }] = useDeleteNotificationMutation()
  const [deleteNotifications, { isLoading: _isDeletingAll }] = useDeleteNotificationsMutation()
  const { data: allNotifications, isLoading: isLoadingNotifications } = useGetNotificationsQuery(undefined, {
    skip: !user,
    pollingInterval: 30000,
  })
  const clientNotifications = allNotifications?.filter((notification) => notification.client === client?.id)
  const hasMediaSiteModule = (client?.modules as Module[])?.some((module) => module.code === 'SITE')

  return (
    <>
      <DrawerTitle
        isLoading={isLoadingNotifications}
        title="Notificaciones"
      />
      {clientNotifications?.length || isLoadingNotifications ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ px: 4.5, pt: 2, pb: 1 }}
        >
          <Typography level="title-lg-light">
            <Skeleton loading={Boolean(isLoadingNotifications)}>Bandeja principal</Skeleton>
          </Typography>
          <IconButton
            disabled={isLoadingNotifications}
            onClick={() => deleteNotifications({ ids: clientNotifications!.map(({ id }) => id) })}
          >
            <Typography
              level="title-lg-light"
              startDecorator={!isLoadingNotifications && <TrashIcon style={{ width: '1.5rem' }} />}
            >
              <Skeleton loading={Boolean(isLoadingNotifications)}>Eliminar todas</Skeleton>
            </Typography>
          </IconButton>
        </Stack>
      ) : (
        <></>
      )}
      <Box sx={styles.notificationsDrawer.box}>
        {isLoadingNotifications ? (
          <Stack gap={1}>
            <NotificationsCardSkeleton />
            <NotificationsCardSkeleton />
            <NotificationsCardSkeleton />
          </Stack>
        ) : clientNotifications?.length ? (
          clientNotifications?.map((notification) => (
            <Box
              key={notification.id}
              sx={{
                ...styles.notificationsDrawer.card,
                opacity: notification.status === 'NEW' ? '100%' : '60%',
                cursor: notification.status === 'NEW' ? 'pointer' : '',
              }}
              onClick={() =>
                notification.status === 'NEW'
                  ? updateNotification({
                      id: notification.id,
                      data: { status: 'READ' },
                    })
                  : () => {}
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography level="h3">{notification.title}</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Tooltip title={`Marcar como ${notification.status !== 'NEW' ? 'no ' : ' '}leído`}>
                    <IconButton
                      onClick={() =>
                        updateNotification({
                          id: notification.id,
                          data: { status: notification.status === 'NEW' ? 'READ' : 'NEW' },
                        })
                      }
                    >
                      {notification.status === 'NEW' ? <MarkEmailUnreadOutlined /> : <DraftsOutlined />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => deleteNotification({ id: notification.id })}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <div
                style={{ marginTop: '1rem' }}
                dangerouslySetInnerHTML={{ __html: notification.message }}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 1 }}
              >
                {Boolean(notification.path) &&
                  (notification?.path?.includes('/mediasite') ? hasMediaSiteModule : true) && (
                    <Button
                      aria-placeholder={notification.path}
                      onClick={() => {
                        navigate(notification.path) // TODO: why not Link?
                      }}
                    >
                      {notification.text_link}
                    </Button>
                  )}
                <Typography level="body-sm">{new Date(notification.created_at).toLocaleString()}</Typography>
              </Stack>
            </Box>
          ))
        ) : (
          <Typography
            level="body-md-light"
            sx={{ mt: 2 }}
          >
            No hay notificaciones.
          </Typography>
        )}
      </Box>
    </>
  )
}

interface UserMenuProps {
  onShowMyProfile: () => void
  onShowNotifications: () => void
}

const UserMenu = ({ onShowMyProfile, onShowNotifications }: UserMenuProps) => {
  const { isInvestor, isOwner } = useSession()
  const { setMode: setJoyMode } = useJoyColorScheme()
  const dispatch = useAppDispatch()
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme()
  const { client, user } = useSession()
  const userState = useAppSelector((state) => state.user)
  const styles = {
    clientMenuIcons: {
      borderRadius: globalStyles.borderRadius,
      justifyContent: 'start',
      px: '0.5rem',
      py: '0.75rem',
    },
    avatar: {
      background: userState.gradientComponents ? globalStyles.gradientColor() : 'var(--joy-palette-primary-solidBg)',
      color: 'white',
    },
  }
  return (
    <>
      <DrawerTitle
        title={
          <Stack
            gap={2}
            justifyContent="start"
            direction="row"
            alignItems="center"
          >
            <Avatar
              size="lg"
              sx={styles.avatar}
            >
              {client?.name.slice(0, 1)}
            </Avatar>
            <Stack>
              <Typography level="title-lg-bold">{`${user?.first_name} ${user?.last_name}`.trim()}</Typography>
              <Typography level="body-md-light">{client?.name}</Typography>
            </Stack>
          </Stack>
        }
      />
      <Stack
        sx={{ paddingTop: 2 }}
        gap={1.25}
      >
        {user?.allowed_clients && user?.allowed_clients?.length > 1 && (
          <UserMenuItem
            label="Empresa"
            items={<ClientSelect fullwidth />}
            icon={<BriefcaseIcon style={{ width: '1.25em' }} />}
          />
        )}
        <UserMenuItem
          label="Perfil"
          items={[
            {
              label: 'Editar mi perfil',
              onClick: () => onShowMyProfile(),
            },
            {
              label: 'Notificaciones',
              onClick: () => onShowNotifications(),
            },
          ]}
          icon={<UserCircleIcon style={{ width: '1.25em' }} />}
        />
        {!isInvestor && !isOwner && (
          <UserMenuItem
            label="Ayuda"
            items={[
              // { label: 'FAQ' },
              {
                label: 'Tickets de soporte',
                path: routes.core.support_tickets,
              },
            ]}
            icon={<QuestionMarkCircleIcon style={{ width: '1.25em' }} />}
          />
        )}
        {false && (
          <UserMenuItem
            label="Tema"
            items={
              <Stack gap={2}>
                <Typography
                  sx={{ pt: 2 }}
                  component={'span'}
                  startDecorator={
                    <Switch
                      checked={mode === 'dark'}
                      onClick={() => {
                        setMaterialMode(mode === 'dark' ? 'light' : 'dark')
                        setJoyMode(mode === 'dark' ? 'light' : 'dark')
                      }}
                    ></Switch>
                  }
                >
                  Modo oscuro
                </Typography>
                <Typography
                  sx={{ pt: 1 }}
                  component={'span'}
                  startDecorator={
                    <Switch
                      checked={userState.gradientComponents}
                      onClick={() => dispatch(toggleGradientComponents())}
                    ></Switch>
                  }
                >
                  Usar gradiente de color (beta)
                </Typography>{' '}
                <Typography
                  sx={{ py: 1 }}
                  component={'span'}
                  startDecorator={
                    <Switch
                      checked={userState.customPalette}
                      onClick={() => dispatch(toggleCustomPalette())}
                    ></Switch>
                  }
                >
                  Usar paleta personalizada (beta)
                </Typography>
                {userState.customPalette && (
                  <Input
                    id="color-picker"
                    type="color"
                    value={userState.color}
                    title="Color de la aplicación"
                    onChange={(e) => {
                      e.persist()
                      dispatch(changeUserColor(e.target.value))
                    }}
                  />
                )}
              </Stack>
            }
            icon={<MoonIcon style={{ width: '1.25em' }} />}
          />
        )}
        <UserMenuItem
          label="Cerrar sesión"
          onClick={() => {
            dispatch(setClient(undefined))
            sessionStorage.removeItem('clientId')
            dispatch(mediacoreApi.util.resetApiState())
            signOut(auth)
          }}
          icon={<PowerIcon style={{ width: '1.25em' }} />}
        />
      </Stack>
    </>
  )
}

interface UserMenuItemProps {
  label: string
  items?: { label: string; path?: string; onClick?: () => void }[] | React.ReactNode
  icon: React.ReactNode
  onClick?: () => void
}

const UserMenuItem = ({ label, items, icon, onClick }: UserMenuItemProps) => {
  return (
    <AccordionGroup onClick={onClick}>
      <Accordion>
        <AccordionSummary indicator={false}>
          <Stack
            direction="row"
            gap={1}
          >
            <Box sx={{ width: '1.5em', display: 'flex', justifyContent: 'center' }}>{icon}</Box>
            <Typography level="title-lg-light">{label}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {Array.isArray(items) ? (
            <Stack gap={0.5}>
              {items?.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  gap={1}
                >
                  <Box sx={{ width: '1.5em', display: 'flex', justifyContent: 'center' }} />
                  <Link
                    to={item.path ?? ''}
                    style={{ cursor: 'pointer', textDecoration: 'none', display: 'block' }}
                    onClick={item.onClick}
                  >
                    <Typography
                      level="body-md-light"
                      color="neutral"
                    >
                      {item.label}
                    </Typography>
                  </Link>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Stack
              sx={{ width: '100%' }}
              direction="row"
              gap={1}
            >
              <Box sx={{ width: '1.5em', display: 'flex', justifyContent: 'center' }} />
              <Box sx={{ width: '100%' }}>{items}</Box>
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  )
}
