import { createSlice } from '@reduxjs/toolkit'
import { ReactNode } from 'react'

interface ConfirmationModalHide {
  open: false
}
interface ConfirmationModalShow {
  open: true
  title: string
  content: ReactNode
  icon?: 'cancel' | 'error' | 'pending'
  onAccept: () => void
}
type ConfirmationModal = ConfirmationModalHide | ConfirmationModalShow

interface State {
  confirmationModal?: Partial<ConfirmationModal>
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    confirmationModal: { open: false },
  } as State,
  reducers: {
    showConfirmationModal: {
      reducer(state, action) {
        state.confirmationModal = {
          ...action.payload,
          open: true,
        }
      },
      prepare(user) {
        return {
          payload: user,
          meta: '',
          error: '',
        }
      },
    },
    hideConfirmationModal: {
      reducer(state) {
        state.confirmationModal = { open: false }
      },
      prepare() {
        return {
          payload: '',
          meta: '',
          error: '',
        }
      },
    },
  },
})

export const { showConfirmationModal, hideConfirmationModal } = modalSlice.actions
