import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/joy'
import React from 'react'

interface Props {
  text?: string
  library?: 'hero'
  children?: React.ReactNode
  width?: string
  color?: string
}

export const InfoTooltip = ({
  text,
  className = '',
  children,
  library,
  width,
  color,
  ...rest
}: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => {
  const splitLines = (text?: string) => text?.split('\n').map((line, index) => <div key={index}>{line}</div>)

  const title = Boolean(text) ? splitLines(text) : typeof children === 'string' ? splitLines(children) : children

  return (
    <Tooltip title={title}>
      {library === 'hero' ? (
        <QuestionMarkCircleIcon style={{ width: width ?? '1em', color }} />
      ) : (
        <i
          {...rest}
          className={`material-icons ${className}`}
          style={{ fontSize: 'inherit', cursor: 'default' }}
        >
          help_outline
        </i>
      )}
    </Tooltip>
  )
}
