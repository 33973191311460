import React, { useEffect } from 'react'
import { useAppDispatch } from '@redux/reduxHooks'
import { setClient } from '@slices/userSlice'
import { useSession } from '@hooks/useSession'
import { Input, ListItem, Option, Select } from '@mui/joy'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Client } from '@types'

interface Props {
  disabled?: boolean
  hidden?: boolean
  fullwidth?: boolean
}

const ClientSelect = ({ disabled, hidden }: Props) => {
  const { client, user } = useSession()
  const [search, setSearch] = React.useState('')

  const dispatch = useAppDispatch()

  const handleClientSelect = (id: number | string | null) => {
    if (!id) return
    const client = user?.allowed_clients?.find((client) => client.id === Number(id))
    sessionStorage.setItem('clientId', id.toString())
    dispatch(setClient(client))
  }

  useEffect(() => {
    if (user?.allowed_clients?.length && !client)
      handleClientSelect(sessionStorage.getItem('clientId') ?? user?.allowed_clients[0]?.id)
  }, [user])

  const clientSearchString = (client: Client) => `${client.id} - ${client.name.toLowerCase()}`

  const selectableClients = user?.allowed_clients?.filter((client) =>
    clientSearchString(client).includes(search.toLowerCase()),
  )

  return (
    <Select
      sx={{
        visibility: hidden ? 'hidden' : 'visible',
      }}
      value={client?.id ?? 0}
      disabled={disabled}
      onChange={(_, value) => handleClientSelect(value)}
      slotProps={{ listbox: { sx: { paddingBlockStart: 0 } } }}
    >
      <ListItem
        sticky
        sx={{ top: 0 }}
      >
        <Input
          sx={{ width: '100%' }}
          placeholder="Buscar empresa"
          endDecorator={<MagnifyingGlassIcon style={{ width: '1.5rem' }} />}
          value={search}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setSearch(e.target.value)}
        />
      </ListItem>
      {user?.allowed_clients?.map((client) => (
        <Option
          sx={{
            display: clientSearchString(client).includes(search.toLowerCase()) ? 'block' : 'none',
          }}
          key={`section-${client.id}`}
          value={client.id}
        >{`${client.id} - ${client.name}`}</Option>
      ))}
      <Option
        sx={{ display: selectableClients?.length ? 'none' : 'block' }}
        value={0}
        disabled
      >
        No se encontraron empresas
      </Option>
    </Select>
  )
}

export default ClientSelect
