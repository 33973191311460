import React, { useState } from 'react'
import { APIProvider } from '@vis.gl/react-google-maps'

interface MapsWrapperProps {
  children: React.ReactNode
}

export const MapsWrapper = ({ children }: MapsWrapperProps) => {
  const [libraries] = useState(['places', 'drawing']) // https://github.com/JustFly1984/react-google-maps-api/issues/238

  return (
    <APIProvider
      libraries={libraries}
      apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? ''}
      language="es-AR"
    >
      {children}
    </APIProvider>
  )
}

export default MapsWrapper
