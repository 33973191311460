import {
  useCreateNotificationSettingMutation,
  useGetNotificationsSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@endpoints/notificationsEndpoint '
import { Module, NotificationSetting } from '@types'
import { pendingToast, updateErrorToast, updateSuccessToast } from '@utils'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSession } from './useSession'

export const UseNotifications = () => {
  const { client, user } = useSession()

  const [updateNotification] = useUpdateNotificationSettingsMutation()
  const [createNotification] = useCreateNotificationSettingMutation()

  const { data: queriedNotificationSetting, isLoading: isLoadingNotificationSetting } =
    useGetNotificationsSettingsQuery({ clientId: client?.id!, user: user?.id! }, { skip: !client })

  const userNotificationSettings = queriedNotificationSetting?.[0]

  const [notification, setNotification] = useState<NotificationSetting['data']>()

  useEffect(() => {
    const updatedNotifications: NotificationSetting['data'] = (client?.modules as Module[])?.map((module) => ({
      module: module.code,
      in_app: true,
      email: true,
    }))
    userNotificationSettings
      ? setNotification(userNotificationSettings?.data ?? [])
      : setNotification(updatedNotifications)
  }, [queriedNotificationSetting, client?.id])

  const handleChangeAndSave = (module: string, notification_type: string) => {
    const updateNotificationArray = (notifications: NotificationSetting['data']) => {
      return notifications.map((notification) =>
        notification.module === module
          ? {
              ...notification,
              [notification_type]: !notification[notification_type as keyof typeof notification],
            }
          : notification,
      )
    }

    setNotification((prevData) => updateNotificationArray(prevData ?? []))

    const toastId = pendingToast(toast, 'Guardando configuración...')
    ;(userNotificationSettings
      ? updateNotification({
          id: userNotificationSettings?.id!,
          client: client?.id!,
          user: user?.id!,
          data: updateNotificationArray(notification ?? []),
        })
      : createNotification({
          client: client?.id!,
          user: user?.id!,
          data: updateNotificationArray(notification ?? []),
        })
    )
      .unwrap()
      .then(() => updateSuccessToast(toast, toastId, 'Configuración guardada con éxito'))
      .catch((error) => {
        updateErrorToast(
          toast,
          toastId,
          error.status === 403 ? error?.data?.detail : `No se pudo guardar la configuración`,
        )
      })
  }

  return { notification, handleChangeAndSave, isLoadingNotificationSetting }
}
