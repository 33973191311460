import React from 'react'

interface Props {
  children: React.ReactNode
  isBusy?: boolean
  className?: string
}

export const FieldSet = ({
  children,
  isBusy,
  className,
  ...rest
}: Props & React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement>) => {
  return (
    <fieldset
      {...rest}
      className={`${isBusy ? 'propagate-wait-cursor' : ''} ${className ?? ''}`}
      style={{
        border: 'none',
      }}
      disabled={isBusy}
    >
      {children}
    </fieldset>
  )
}
