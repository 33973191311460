import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  updateByIdMutation,
  deleteByIdMutation,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, InvestorNews, Profile } from '@types'
const investorNewsEndpointURL = 'investor/novedades'
// const developmentsEndpointURL = 'investor/emprendimientos'

const investorApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvestorNewsById: builder.query<InvestorNews, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(investorNewsEndpointURL),
      providesTags: (_result, _error, { id }) => [{ type: 'InvestorNews', id }],
    }),
    deleteInvestorNews: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation(investorNewsEndpointURL),
      invalidatesTags: ['InvestorNewsTable'],
    }),
    createInvestorNews: builder.mutation<InvestorNews, { data: Partial<InvestorNews> }>({
      query: createOneMutation(investorNewsEndpointURL),
      invalidatesTags: ['InvestorNewsTable'],
    }),
    updateInvestorNews: builder.mutation<InvestorNews, { id: number; data: Partial<InvestorNews> }>({
      query: updateByIdMutation(investorNewsEndpointURL),
      invalidatesTags: ['InvestorNewsTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNews } = await queryFulfilled
          dispatch(
            investorApi.util.updateQueryData('getInvestorNewsById', { id, clientId: updatedNews.client }, (draft) => {
              Object.assign(draft, updatedNews)
            }),
          )
        } catch {}
      },
    }),

    uploadInvestorNewsImages: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(investorNewsEndpointURL, 'galeria'),
      invalidatesTags: ['Property', 'ProductTable'],
    }),
    uploadInvestorNewsFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(investorNewsEndpointURL, 'galeria_archivos'),
      invalidatesTags: ['InvestorNews', 'InvestorNewsTable'],
    }),

    // ** Deprecated */
    // getDevelopments: builder.query<Developments[], { clientId: number }>({
    //   query: getAllByClientIdQuery(developmentsEndpointURL),
    //   providesTags: (result) =>
    //     result ? [...result.map(({ id }) => ({ type: 'Development' as const, id })), 'Development'] : ['Development'],
    // }),
    // getDevelopmentById: builder.query<Developments, { id: number; clientId: number }>({
    //   query: getByIdAndClientIdQuery(developmentsEndpointURL),
    //   providesTags: (_result, _error, { id }) => [{ type: 'Development', id }],
    // }),
    // deleteDevelopment: builder.mutation<void, { id: number; clientId: number }>({
    //   query: deleteByIdMutation(developmentsEndpointURL),
    //   invalidatesTags: ['Profile'],
    //   async onQueryStarted({ id, clientId }, { dispatch, queryFulfilled }) {
    //     try {
    //       await queryFulfilled
    //       dispatch(
    //         investorApi.util.updateQueryData('getDevelopments', { clientId }, (draftDevelopments) => {
    //           return draftDevelopments.filter((draftDevelopment) => draftDevelopment.id !== id)
    //         }),
    //       )
    //     } catch {}
    //   },
    // }),
    // createDevelopment: builder.mutation<Developments, { data: Partial<Developments> }>({
    //   query: createOneMutation(developmentsEndpointURL),
    //   invalidatesTags: ['Profile'],
    //   async onQueryStarted(_data, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data: createdDevelopment } = await queryFulfilled
    //       dispatch(
    //         investorApi.util.updateQueryData(
    //           'getDevelopments',
    //           { clientId: createdDevelopment.client },
    //           (draftDevelopments) => {
    //             draftDevelopments.push(createdDevelopment)
    //             return draftDevelopments
    //           },
    //         ),
    //       )
    //     } catch {}
    //   },
    // }),
    // updateDevelopment: builder.mutation<Developments, { id: number; data: Partial<Developments> }>({
    //   query: updateByIdMutation(developmentsEndpointURL),
    //   invalidatesTags: ['Profile'],
    //   async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data: updatedDevelopment } = await queryFulfilled
    //       dispatch(
    //         investorApi.util.updateQueryData(
    //           'getDevelopmentById',
    //           { id, clientId: updatedDevelopment.client },
    //           (draft) => {
    //             Object.assign(draft, updatedDevelopment)
    //           },
    //         ),
    //       )
    //       dispatch(
    //         investorApi.util.updateQueryData(
    //           'getDevelopments',
    //           { clientId: updatedDevelopment.client },
    //           (draftDevelopments) => {
    //             return draftDevelopments.map((draftDevelopment) =>
    //               draftDevelopment.id !== id ? draftDevelopment : updatedDevelopment,
    //             )
    //           },
    //         ),
    //       )
    //     } catch {}
    //   },
    // }),

    createInvestor: builder.mutation<Profile, { data: Partial<Profile> }>({
      query: createOneMutation('investor/inversores'),
      invalidatesTags: ['User', 'Development', 'Profile', 'Investor', 'InvestorTable'],
    }),
    deleteInvestor: builder.mutation<any, { id: number }>({
      query: deleteByIdMutation('investor/inversores'),
      invalidatesTags: ['User', 'Development', 'Profile', 'Investor', 'TokkoDevelopment', 'InvestorTable'],
    }),
    getInvestor: builder.query<Profile, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('investor/inversores'),
      providesTags: (_result, _error, { id }) => [{ type: 'Investor', id }],
    }),
    updateInvestor: builder.mutation<Profile, { id: number; data: Partial<Profile> }>({
      query: updateByIdMutation('investor/inversores'),
      invalidatesTags: ['Development', 'Profile', 'Investor', 'Owner', 'TokkoDevelopment', 'InvestorTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePrefetch: usePrefetchInvestor,
  useGetInvestorNewsByIdQuery,
  useDeleteInvestorNewsMutation,
  useCreateInvestorNewsMutation,
  useUpdateInvestorNewsMutation,
  useCreateInvestorMutation,
  useDeleteInvestorMutation,
  useGetInvestorQuery,
  useUpdateInvestorMutation,
  useUploadInvestorNewsImagesMutation,
  useUploadInvestorNewsFilesMutation,
} = investorApi
