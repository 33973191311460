import * as React from 'react'
import TopNavBar from '@components/TopNavBar'
import SideNavBar from '@components/SideNavBar'
import type { PageProps } from 'gatsby'
import { useState } from 'react'
import { MainSpinner } from '@components/MainSpinner'
import { useSession } from '@hooks/useSession'
import JoyConfirmationModal from './JoyConfirmationModal'
import { useAppDispatch, useAppSelector } from '@redux/reduxHooks'
import { hideConfirmationModal } from '@slices/modalSlice'

const NavBarsWrapper = ({ children }: PageProps) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { user } = useSession()
  const { confirmationModal } = useAppSelector((state) => state.modal)
  const dispatch = useAppDispatch()
  return (
    <>
      <MainSpinner show={!user} />
      <TopNavBar setShowMobileMenu={setShowMobileMenu} />
      <SideNavBar
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
      {children}
      {confirmationModal?.open && (
        <JoyConfirmationModal
          open={confirmationModal.open}
          title={confirmationModal.title ?? ''}
          content={confirmationModal.content}
          icon={confirmationModal.icon}
          onAccept={() => {
            confirmationModal.onAccept?.()
            dispatch(hideConfirmationModal())
          }}
          onClose={() => dispatch(hideConfirmationModal())}
        />
      )}
    </>
  )
}

export default NavBarsWrapper
