import { navigate } from 'gatsby'
import React from 'react'
import { Role, SideBarItemType } from '@types'
import { authRoles, routes } from '@constants'
import { usePrefetchBlog } from '@endpoints/newsEndpoint'
import { usePrefetchOwnerEndpoint } from '@endpoints/ownersEndpoint'
import { usePrefetchAppraisals } from '@endpoints/appraisalsEndpoint'
import { usePrefetchReports } from '@endpoints/reportsEndpoint'
import Drawer from '@mui/joy/Drawer'
import Typography from '@mui/joy/Typography'
import Box from '@mui/joy/Box'
import ModalClose from '@mui/joy/ModalClose'
import { List, ListItem, ListItemButton, ListItemContent } from '@mui/joy'
import Stack from '@mui/joy/Stack'
import { globalStyles } from '@styles/styles'
import { useSession } from '@hooks/useSession'
interface Props {
  showMobileMenu: boolean
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

interface SideBarProps {
  item: SideBarItemType
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const styles = {
  sidebarItem: {
    listItem: {
      marginBottom: '0.5rem',
    },
    listItemButton: {
      borderRadius: globalStyles.borderRadius,
      fontSize: '14px',
    },
    closedList: {
      transition: 'all 0.15s ease 0s',
      transform: 'scaleY(0)',
      maxHeight: '0px',
      marginBottom: '0',
    },
    openList: {
      transition: 'all 0.15s ease 0s',
      transform: 'scaleY(1)',
      marginBottom: '2rem',
    },
  },
  sideBar: {
    box: {
      paddingX: 4,
      paddingY: 1,
    },
  },
  drawer: {
    title: {
      width: globalStyles.leftPanelWidth,
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
    },
    modalClose: {
      position: 'relative',
      right: 0,
      top: 0,
    },
    agencyTitle: {
      width: globalStyles.leftPanelWidth,
      paddingX: 3,
      height: globalStyles.navBarHeight,
      borderBottom: globalStyles.border,
      borderTop: globalStyles.border,
    },
  },
}

const isBrowser = typeof window !== 'undefined'

const SideBarItem = ({ item, setShowMobileMenu }: SideBarProps) => {
  return (
    <>
      <ListItem sx={styles.sidebarItem.listItem}>
        <ListItemButton
          variant="plain"
          sx={styles.sidebarItem.listItemButton}
          onClick={() => {
            if (item.link) {
              navigate(item.link)
              setShowMobileMenu(false)
              return
            }
            item.prefetchFunctions?.()
          }}
        >
          <ListItemContent>{item.title}</ListItemContent>
        </ListItemButton>
      </ListItem>
    </>
  )
}

const SideNavBar = ({ showMobileMenu, setShowMobileMenu }: Props) => {
  const { client, user } = useSession()
  const prefetchBlogTags = usePrefetchBlog('getTags')
  const prefetchAppraisals = usePrefetchAppraisals('getAppraisals')
  const prefetchReports = usePrefetchReports('getReports')
  const prefetchOwnerNews = usePrefetchOwnerEndpoint('getOwnerNews')

  const { isInvestor, isOwner } = useSession()

  const sidebarItems = [
    {
      title: 'Inicio',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.core.root,
    },
    {
      title: 'MediaTracker',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.tracker.analytics,
    },
    {
      title: 'MediaBlog',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.blog.news,
      prefetchFunctions: () => {
        if (isBrowser && !location?.pathname.includes('/mediablog')) {
          // Avoid refetching while filling form
          prefetchBlogTags({ clientId: client?.id! }, { ifOlderThan: 60 })
        }
      },
    },
    {
      title: 'MediaPush',
      prefetchFunctions: () => {},
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.push.actions,
    },
    {
      title: 'MediaInvestor',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.investor.news,
    },
    {
      title: 'MediaOwner',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.owner.communications,
      prefetchFunctions: () => {
        if (isBrowser && !location?.pathname.includes('/mediaowner')) {
          prefetchAppraisals({ clientId: client?.id! }, { ifOlderThan: 60 })
          prefetchReports({ clientId: client?.id! }, { ifOlderThan: 60 })
          prefetchOwnerNews({ clientId: client?.id! }, { ifOlderThan: 60 })
        }
      },
    },
    {
      title: 'MediaJobs',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: routes.jobs.positions,
    },
    {
      title: 'MediaMarketing',
      showItem: Boolean(!isInvestor) && Boolean(!isOwner),
      link: client?.gantt_url ? routes.agency.roadmaps : routes.agency.resources,
    },
  ]

  const sideBar = (
    <Box sx={styles.sideBar.box}>
      <List>
        {Boolean(client?.id) &&
          sidebarItems
            .filter((sidebarItem) => sidebarItem.showItem)
            .map((sidebarItem) => (
              <SideBarItem
                item={sidebarItem}
                key={sidebarItem.title}
                setShowMobileMenu={setShowMobileMenu}
              />
            ))}
      </List>
    </Box>
  )

  const agencyLinks = [
    {
      title: 'Grupos y permisos',
      link: '/configuracion/grupos_y_permisos',
    },
    {
      title: 'Componentes MediaSite',
      link: '/configuracion/componentes_mediasite',
    },
    {
      title: 'Registros DNS',
      link: '/mediasite/dns',
    },
    {
      title: 'Templates',
      link: '/mediasite/templates',
    },
    {
      title: 'Categorias de templates',
      link: '/mediasite/templates/categorias',
    },
    {
      title: 'Tipografías',
      link: '/mediasite/fuentes',
    },
  ]

  return (
    <Drawer
      anchor="left"
      open={showMobileMenu}
      onClose={() => setShowMobileMenu(false)}
      slotProps={{
        content: {
          sx: {
            width: styles.drawer.title.width,
          },
        },
      }}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={styles.drawer.title}
      >
        <Typography level="h3">Módulos</Typography>
        <ModalClose
          size="lg"
          sx={styles.drawer.modalClose}
          onClick={() => setShowMobileMenu(false)}
        />
      </Stack>
      {sideBar}
      {authRoles.ADMIN.includes((user?.role as Role)?.name) && (
        <>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            sx={styles.drawer.agencyTitle}
          >
            <Typography level="h3">Accesos Agencia</Typography>
          </Stack>
          <Box sx={styles.sideBar.box}>
            <List>
              {agencyLinks.map(({ title, link }) => (
                <ListItem
                  key={title}
                  sx={styles.sidebarItem.listItem}
                >
                  <ListItemButton
                    variant="plain"
                    sx={styles.sidebarItem.listItemButton}
                    onClick={() => {
                      if (link) {
                        navigate(link)
                        setShowMobileMenu(false)
                        return
                      }
                    }}
                  >
                    <ListItemContent>{title}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </Drawer>
  )
}

export default SideNavBar
