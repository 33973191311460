import React from 'react'
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider, extendTheme, Theme } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useAppSelector } from '@redux/reduxHooks'
import { GlobalStyles, stepClasses, stepIndicatorClasses } from '@mui/joy'
import Color from 'color'
import { globalStyles } from '@styles/styles'
interface ThemeWrapperProps {
  children: React.ReactNode
}

const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const userState = useAppSelector((state) => state.user)
  const materialTheme = materialExtendTheme({
    typography: {
      fontFamily: 'Inter',
    },
    colorSchemes: {
      light: {
        palette: {
          // background: {
          //   default: globalStyles.defaultWhite,
          //   paper: globalStyles.defaultWhite,
          // },
          // common: {
          //   background: globalStyles.defaultWhite,
          // },
        },
      },
    },
  })

  // https://github.com/KaindlJulian/material-palette-generator/
  const contrasts = [0.15, 0.3, 0.5, 0.7, 0.85, 1, 0.85, 0.7, 0.5, 0.35]

  const mainColor = Color(userState.color ?? '#0B6BCB').rgb()
  const mainPalette = contrasts.map((contrast, index) =>
    Color(index < 5 ? 'white' : 'black')
      .mix(mainColor, contrast)
      .hex(),
  )

  const customPalette = {
    '50': mainPalette[0],
    '100': mainPalette[1],
    '200': mainPalette[2],
    '300': mainPalette[3],
    '400': mainPalette[4],
    '500': mainPalette[5],
    '600': mainPalette[6],
    '700': mainPalette[7],
    '800': mainPalette[8],
    '900': mainPalette[9],
  }

  const themeGradient = (theme: Theme) =>
    `linear-gradient(to right, ${theme.colorSchemes.light.palette.primary[400]}, ${theme.colorSchemes.light.palette.primary[600]})`

  const joyTheme = extendTheme({
    components: {
      ...(userState.gradientComponents && {
        JoySwitch: {
          styleOverrides: {
            root: ({ theme, ownerState }) => ({
              ...(ownerState?.checked && {
                '&:not(:hover)>.MuiSwitch-track': {
                  background: themeGradient(theme),
                },
              }),
            }),
          },
        },
        JoyButton: {
          styleOverrides: {
            root: ({ theme, ownerState }) => ({
              ...(ownerState?.variant === 'solid' && {
                '&:not(:hover)': {
                  background: themeGradient(theme),
                },
              }),
            }),
          },
        },
        JoyStepper: {
          styleOverrides: {
            root: ({ theme }) => ({
              [`& .${stepClasses.active}`]: {
                [`& .${stepIndicatorClasses.root}`]: {
                  background: themeGradient(theme),
                },
              },
              [`& .${stepClasses.completed}`]: {
                '&::after': { background: themeGradient(theme) },
                [`& .${stepIndicatorClasses.root}`]: {
                  background: themeGradient(theme),
                },
              },
            }),
          },
        },
      }),
      JoyTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'initial',
              span: {
                color: 'var(--joy-palette-text-primary)',
              },
            },
          },
        },
      },
      JoyDivider: {
        styleOverrides: {
          root: {
            opacity: 1,
          },
        },
      },
      JoyTypography: {
        styleOverrides: {
          root: {
            letterSpacing: 0,
          },
        },
      },
      JoySelect: {
        styleOverrides: {
          root: {
            minHeight: globalStyles.inputHeight,
          },
        },
      },
      JoyInput: {
        styleOverrides: {
          root: {
            minHeight: globalStyles.inputHeight,
          },
        },
      },
      JoyTextarea: {
        styleOverrides: {
          root: {
            minHeight: globalStyles.inputHeight,
          },
        },
      },
    },
    typography: {
      h1: {
        fontSize: '3rem',
        fontWeight: 600,
      },
      h4: {
        fontWeight: 500,
      },
      'title-lg': {
        fontSize: '1.125rem',
      },
      'title-lg-bold': {
        fontSize: '1.125rem',
        fontWeight: 600,
      },
      'title-lg-light': {
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      'body-sm': {
        fontSize: '0.825rem',
      },
      'body-sm-bold': {
        fontSize: '0.825rem',
        fontWeight: 700,
      },
      'body-sm-light': {
        fontSize: '0.825rem',
        fontWeight: 400,
      },
      'body-md': {
        fontSize: '1rem',
      },
      'body-md-bold': {
        fontSize: '1rem',
        fontWeight: 600,
      },
      'body-md-light': {
        fontSize: '1rem',
        fontWeight: 400,
      },
    },
    colorSchemes: {
      light: {
        palette: {
          background: {
            // surface: globalStyles.defaultWhite,
            // body: globalStyles.defaultWhite,
          },
          ...(userState.customPalette && { primary: customPalette }),
        },
      },
      dark: {
        palette: {
          ...(userState.customPalette && { primary: customPalette }),
        },
      },
    },
  })
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <GlobalStyles
        styles={{
          svg: {
            color: 'var(--Icon-color)',
            fontSize: 'var(--Icon-fontSize, 24px)',
          },
        }}
      />
      <JoyCssVarsProvider theme={joyTheme}>
        <CssBaseline />
        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  )
}

export default ThemeWrapper
