import { Grid } from '@mui/joy'
import React, { ComponentProps, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

/*
 * Simple editor component that takes placeholder text as a prop
 */
/*

 * PropType validation
 */
interface Props {
  placeholder?: string
  content: string
  defaultValue?: string
  handleContentChange?: (content: string) => void
  readOnly?: boolean
  noToolbar?: boolean
  gridProps?: ComponentProps<typeof Grid>
}

const StyledDiv = styled.div`
  .ql-video {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 40vh;
    @media (max-width: 600px) {
      height: 20vh;
    }
  }
  .ql-snow {
    .ql-editor {
      blockquote {
        opacity: 0.6;
        margin: 3rem 0;
        display: block;
        font-size: 1.2rem;
        padding: 0 2.5rem;
        position: relative;
        text-align: center;
        border: none !important;

        &::before,
        &::after {
          font-size: 7rem;
          line-height: 1rem;
          opacity: 0.4;
        }

        &::after {
          content: '”';
          margin-left: 1rem;
          margin-top: auto;
          position: absolute;
          right: 0;
          bottom: -1rem;
        }

        &::before {
          content: '‟';
          top: 1.5rem;
          margin-right: 1rem;
          left: 0;
          position: absolute;
        }
      }
    }
    .ql-tooltip {
      left: 0 !important;
    }
  }
`

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block', 'link'],

    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ['image', 'video'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'align',
  'background',
  'bold',
  'blockquote',
  'bullet',
  'color',
  'code',
  'code-block',
  'clean',
  'direction',
  'font',
  'header',
  'italic',
  'indent',
  'image',
  'list',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'video',
]

const RichTextEditor = ({
  content,
  placeholder,
  handleContentChange,
  defaultValue,
  readOnly = false,
  noToolbar,
  gridProps,
}: Props) => {
  const ref = useRef<ReactQuill>(null)
  const richTextEditor = (
    <StyledDiv
      onClick={(e) => {
        const className = (e.target as HTMLElement).className
        if (typeof className !== 'string') return
        className.includes('ql-container') && ref.current?.getEditor().focus()
      }}
    >
      <ReactQuill
        className={readOnly ? 'no-border-children' : undefined}
        ref={ref}
        readOnly={readOnly}
        theme="snow"
        onChange={handleContentChange}
        value={content}
        modules={{
          ...modules,
          toolbar: noToolbar ? false : modules.toolbar,
          clipboard: {
            // Remove extra line before blockquote
            matchVisual: false,
          },
        }}
        formats={formats}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </StyledDiv>
  )

  return !gridProps ? richTextEditor : <Grid {...gridProps}>{richTextEditor}</Grid>
}

export default RichTextEditor
