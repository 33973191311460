import { chartsColors } from '@constants'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Place } from '@mui/icons-material'
import { Grid, IconButton, Input, Stack, Typography } from '@mui/joy'
import React from 'react'
import { InfoTooltip } from './InfoTooltip'

interface ReferencePointGridProps {
  reference_points?: { name: string }[]
  onDelete?: (index: number) => void
  onTextChange?: (text: string, index: number) => void
  firstPointName?: string
  tooltip?: string
}

export const ReferencePointGrid = ({
  reference_points,
  onDelete,
  onTextChange,
  firstPointName,
  tooltip,
}: ReferencePointGridProps) => {
  return (
    <Grid
      xs={12}
      container
      spacing={2}
    >
      <Grid md={3}>
        <Stack gap={1}>
          <Typography
            endDecorator={tooltip && <InfoTooltip text={tooltip} />}
            level="body-md-bold"
          >
            Puntos de interés
          </Typography>
          <Input
            sx={{ borderColor: '#EA4335!important' }}
            disabled
            value={firstPointName ?? ''}
            placeholder="Nombre del punto de referencia"
            startDecorator={<Place sx={{ color: '#EA4335' }} />}
          />
        </Stack>
      </Grid>
      {reference_points?.map(({ name }, index: number) => (
        <Grid
          md={3}
          key={`point-${index}`}
          sx={{ alignItems: 'end', display: 'flex' }}
        >
          <Input
            sx={{
              '&:not(:focus)': {
                borderColor: chartsColors[index],
              },
            }}
            disabled={Boolean(!onTextChange)}
            value={name ?? ''}
            onChange={(e) => onTextChange?.(e.target.value, index)}
            placeholder="Nombre del punto de referencia"
            startDecorator={<Place sx={{ color: chartsColors[index] }} />}
            endDecorator={
              Boolean(onDelete) && (
                <IconButton>
                  <TrashIcon onClick={() => onDelete?.(index)} />
                </IconButton>
              )
            }
          />
        </Grid>
      ))}
    </Grid>
  )
}
