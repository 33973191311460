import { mediacoreApi } from '@apis/mediacoreApi'
import { deleteByIdMutation, getAllQuery, getByIdAndClientIdQuery, updateByIdMutation } from '@apis/mediacoreQueries'
import { Notification, NotificationSetting } from '@types'

const notificationsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsCount: builder.query<{ client: number; count: number }[], void>({
      query: getAllQuery('core/notificaciones/count'),
      providesTags: ['NotificationsCount'],
    }),
    getNotifications: builder.query<Notification[], void>({
      query: getAllQuery('core/notificaciones'),
      providesTags: ['Notification'],
    }),
    updateNotification: builder.mutation<Notification, { id: number; data: Partial<Notification> }>({
      query: updateByIdMutation('core/notificaciones'),
      invalidatesTags: ['NotificationsCount'],
      async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
            return draftNotifications.map((draftNotification) =>
              draftNotification.id !== id ? draftNotification : Object.assign({}, draftNotification, data),
            )
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    deleteNotifications: builder.mutation<void, { ids: number[] }>({
      query: ({ ids }: { ids: number[] }) => ({
        url: `core/notificaciones/delete_multiple/`,
        method: 'Delete',
        body: { ids },
      }),
      invalidatesTags: ['NotificationsCount'],
      async onQueryStarted({ ids }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
            return draftNotifications.filter((draftNotification) => !ids.includes(draftNotification.id))
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),

    deleteNotification: builder.mutation<void, { id: number }>({
      query: deleteByIdMutation('core/notificaciones'),
      invalidatesTags: ['NotificationsCount'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData('getNotifications', undefined, (draftNotifications) => {
            return draftNotifications.filter((draftNotification) => draftNotification.id !== id)
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getNotificationsSettings: builder.query<NotificationSetting[], { user: number; clientId: number }>({
      query: ({ clientId: client, user }: { clientId: number; user: number }) => ({
        url: `core/notificaciones-configuracion/`,
        method: 'GET',
        params: { format: 'json', client, user },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'NotificationSetting' as const, id })), 'NotificationSetting']
          : ['NotificationSetting'],
    }),
    getNotificationSettingById: builder.query<NotificationSetting, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('core/notificaciones-configuracion'),
      providesTags: (_result, _error, { id }) => [{ type: 'NotificationSetting', id }],
    }),
    updateNotificationSettings: builder.mutation<
      NotificationSetting,
      { id: number; client: number; user: number; data: NotificationSetting['data'] }
    >({
      query: ({
        id,
        data,
        client,
        user,
      }: {
        id: number
        data: NotificationSetting['data']
        client: number
        user: number
      }) => ({
        url: `core/notificaciones-configuracion/${id}/`,
        method: 'PATCH',
        body: { id, data, client, user },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNotification } = await queryFulfilled
          dispatch(
            notificationsApi.util.updateQueryData(
              'getNotificationSettingById',
              { id, clientId: updatedNotification.client },
              (draft) => {
                Object.assign(draft, updatedNotification)
              },
            ),
          )
          dispatch(
            notificationsApi.util.updateQueryData(
              'getNotificationsSettings',
              { user: updatedNotification.user, clientId: updatedNotification.client },
              (draft) => {
                return draft.map((notification) => (notification.id !== id ? notification : updatedNotification))
              },
            ),
          )
        } catch {}
      },
    }),
    createNotificationSetting: builder.mutation<
      NotificationSetting,
      { user: number; client: number; data: Partial<NotificationSetting['data']> }
    >({
      query: ({ user, client, data }: { user: number; client: number; data: NotificationSetting['data'] }) => ({
        url: `core/notificaciones-configuracion/`,
        method: 'POST',
        body: { user, client, data },
      }),
      invalidatesTags: ['User'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdNotificationSetting } = await queryFulfilled
          dispatch(
            notificationsApi.util.updateQueryData(
              'getNotificationsSettings',
              { clientId: createdNotificationSetting.client, user: createdNotificationSetting.user },
              (draft) => {
                draft.unshift(createdNotificationSetting)
                return draft
              },
            ),
          )
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useDeleteNotificationsMutation,
  useUpdateNotificationSettingsMutation,
  useGetNotificationsSettingsQuery,
  useCreateNotificationSettingMutation,
} = notificationsApi
