import { SvgIcon } from '@mui/joy'
import React from 'react'

export const GoogleIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="16px"
        height="16px"
      >
        <path
          fill="#4285F4"
          d="M24 9.5c3.88 0 6.36 1.68 7.77 3.09l5.68-5.67C34.68 4.5 29.95 2 24 2 14.82 2 7.15 7.82 4.57 15.7l6.91 5.36C13.09 14.23 18.1 9.5 24 9.5z"
        />
        <path
          fill="#34A853"
          d="M46.56 24.29c0-1.6-.14-3.14-.39-4.64H24v9.18h12.83c-.55 2.94-2.23 5.45-4.72 7.11l6.91 5.36C43.07 36.81 46.56 30.99 46.56 24.29z"
        />
        <path
          fill="#FBBC05"
          d="M10.15 27.05c-.39-1.14-.61-2.36-.61-3.61s.22-2.47.61-3.61L3.24 14.47C1.68 17.47 1 20.65 1 24s.68 6.53 2.24 9.53l6.91-5.36z"
        />
        <path
          fill="#EA4335"
          d="M24 46c5.95 0 10.68-1.98 14.23-5.38l-6.91-5.36C29.98 36.66 27.65 37.5 24 37.5c-5.9 0-10.91-4.73-11.82-10.84l-6.91 5.36C7.15 40.18 14.82 46 24 46z"
        />
        <path
          fill="none"
          d="M0 0h48v48H0z"
        />
      </svg>
    </SvgIcon>
  )
}
